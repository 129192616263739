import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, Typography, Container, MenuItem, Select, InputLabel, LinearProgress } from '@mui/material';
import { Alert, LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import { Timestamp } from '@firebase/firestore';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import UserContext from '../contexts/UserContext';

import { useFetchAllPrograms } from '../services/programs_service';
import { createCoachChatSession } from '../services/sessions_service';
import { useFetchUsers } from '../services/users_service';

// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function AddSessionPage() {
  const { user } = useContext(UserContext);
  const { programs } = useFetchAllPrograms();
  const navigate = useNavigate();

  const [payload, setPayload] = useState({});
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState();

  let filteredPrograms = programs;
  if (programs && !user.userRoles.includes('ADMIN'))
    filteredPrograms = programs.filter(program => program.coaches_id.includes(user.id));

  function attachNonFormFields(payload) {
    payload.created_at = Timestamp.now();
    payload.updated_at = Timestamp.now();
    payload.coachId = user?.id;
    return payload;
  }

  function navigateToSessionsPage() {
    navigate('/dashboard/chats');
  }

  async function handleSubmit(event) {
    event.preventDefault();

    attachNonFormFields(payload);

    setError(null);

    if (!payload.members || payload.members.length === 0) {
      setError(t('Select a member'));
      return;
    }

    setIsLoading(true);

    Promise.all(payload.members.map((member) => createCoachChatSession(payload, member, user)))
      .then(navigateToSessionsPage)
      .catch((error) => setError(error.message))
      .finally(() => setIsLoading(false));
  }

  const onChangeProgram = (event) => {
    setSelectedProgram(null);
    const programId = event.target.value;
    setSelectedProgram(filteredPrograms.find((program) => program.id === programId));
    setPayload((payload) => ({ ...payload, programId, members: [] }));
  };


  const onChangeMembers = (members) => {
    setPayload((payload) => ({ ...payload, members }));
  };


  return (
    <>
      {isLoading && <LinearProgress />}
      <Helmet>
        <title> {t('start_a_new_conversation')} | MMCX </title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              {t('start_a_new_conversation')}
            </Typography>

            <form onSubmit={handleSubmit}>
              <Stack spacing={3} mb={3}>
                <InputLabel id="program-label">{t('program')}</InputLabel>
                <Select
                  labelId="program-label"
                  label={t('program')}
                  value={payload.programId}
                  onChange={onChangeProgram}
                >
                  {filteredPrograms.map((program) => (
                    <MenuItem key={program.id} value={program.id}>
                      {program.name}
                    </MenuItem>
                  ))}
                </Select>

                {selectedProgram && <InputLabel id="users-type-label">{t('Members')}</InputLabel>}
                {selectedProgram && (
                  <UserSelectSection
                    userIds={selectedProgram.members}
                    selectedUsers={payload.members.map(m => m.id)}
                    onChangeSelect={onChangeMembers}
                  />
                )}
              </Stack>

              {error && <Alert severity="error">{error}</Alert>}
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
                disabled={isLoading}
              >
                {t('create')}
              </LoadingButton>
            </form>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

UserSelectSection.propTypes = {
  userIds: PropTypes.array,
  selectedUsers: PropTypes.array,
  onChangeSelect: PropTypes.func,
};

function UserSelectSection({ userIds, selectedUsers, onChangeSelect }) {
  const { users } = useFetchUsers(userIds ?? []);

  function onChange(event) {
    event.preventDefault();
    onChangeSelect(users.filter((user) => event.target.value.includes(user.id)));
  }

  return (
    <Select multiple labelId="users-type-label" label={'Users'} value={selectedUsers} onChange={(e) => onChange(e)}>
      {(userIds ? users : []).map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
}
