// component
import i18n from '../../../i18n';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1, mr: 1 }} />;

const navConfig = [
  {
    title: i18n.t('Dashboard'),
    path: '/dashboard/app',
    icon: icon('dashboard2'),
    unauthorized: [],
  },
  {
    title: i18n.t('Coaches'),
    path: '/dashboard/coaches',
    icon: icon('coaches2'),
    unauthorized: ['COACH'],
  },
  {
    title: i18n.t('users'),
    path: '/dashboard/users',
    icon: icon('coaches2'),
    unauthorized: ['COACH'],
  },
  {
    title: i18n.t('programs'),
    path: '/dashboard/programs',
    icon: icon('programs2'),
    unauthorized: ['COACH'],
  },
  {
    title: i18n.t('My Programs'),
    path: '/dashboard/my-programs',
    icon: icon('programs2'),
    unauthorized: [],
  },
  {
    title: i18n.t('Categories'),
    path: '/dashboard/categories',
    icon: icon('sessions2'),
    unauthorized: ['COACH'],
  },
  {
    title: i18n.t('Chats'),
    path: '/dashboard/chats',
    icon: icon('chat2'),
    unauthorized: [],
  },
];

export default navConfig;
