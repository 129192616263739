import { Alert, CircularProgress, Box, Button, Card, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton, MenuItem, Popover, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Timestamp } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { addQualification, deleteQualification, editCoachQualificationById, getCoachQualifications, useFetchCoaches } from "../../services/coaches_service";
import FileUpload from "../file-upload";
import Iconify from "../iconify";
import Notification from "../notification";
import Scrollbar from "../scrollbar/Scrollbar";
import i18n from "../../i18n";
import { uploadFile } from "../../utils/fileUploader";
import { UserListHead } from "../../sections/@dashboard/user";
import { formatImage } from "../../utils/formatImage";



const TABLE_HEAD2 = [
    { id: 'programName', label: i18n.t('Name of Program'), alignRight: false },
    { id: 'qualificationName', label: i18n.t('Qualification'), alignRight: false },
    { id: 'yearAcquired', label: i18n.t('Date Acquired'), alignRight: false },
    { id: 'file', label: i18n.t('Certificates'), alignRight: false },
    {},
];


const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledContent = styled('div')(() => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
}));

function Qualifications({ id: coachId }) {
    const [image, setImage] = useState(null);
    const [editingQualification, setEditingQualification] = useState(null);
    const [open, setOpen] = useState(false);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [qualifications, setQualifications] = useState([]);
    const { t } = useTranslation();
    const { coaches, reloadCoaches } = useFetchCoaches();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [certificatePreview, setCertificatePreview] = useState('');
    const [qualificationData, setQualificationData] = useState({
        programName: '',
        name: '',
        qualification: '',
        date: '',
        certificates: [],
    });
    const formRef = useRef(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedQualificationIdForDelete, setSelectedQualificationIdForDelete] = useState(null);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [snackData, setSnackData] = useState({
        isOpen: false,
        isSuccess: true,
    });
    const [isCertifDialogOpen, setIsCertifDialogOpen] = useState(false);
    const [images, setImages] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setQualificationData({
            ...qualificationData,
            [name]: value,
        });
        // Clear errors for this field
        setFormErrors({
            ...formErrors,
            [name]: '',
        });
    };

    const handleOpenDeleteDialog = (id) => {
        setSelectedQualificationIdForDelete(id);
        setIsDeleteDialogOpen(true);
    };

    const initialQualificationData = {
        programName: '',
        qualification: '',
        date: '',
    };
    const resetForm = () => {
        setQualificationData(initialQualificationData);
    };

    const validateQualificationData = (data) => {
        const errors = {};
        if (!data.programName.trim()) errors.programName = 'Program name is required';
        if (!data.qualification.trim()) errors.qualification = 'Qualification is required';
        if (!data.date) errors.date = 'Date is required';
        if (!data.certificates.length) {
            errors.certificate = 'A file is required';
        } else {
            // Further file validations (type, size, etc.) go here
        }
        return errors;
    };

    const handleCloseSnack = () => {
        setSnackData((prev) => ({ ...prev, isOpen: false }));
    };

    const handleFileChange = (files) => {
        if (files && files.length) {
            setQualificationData({
                ...qualificationData,
                certificates: files,
            });
            // setImage(file);

            // Clear file upload error
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                certificate: '',
            }));
    }
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    useEffect(() => {
        const fetchQualifications = async () => {
            const qualificationsData = await getCoachQualifications(coachId);
            setQualifications(qualificationsData);
        };

        fetchQualifications();
    }, [coachId]);

    const handleSave = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const errors = validateQualificationData(qualificationData);
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            setIsLoading(true);

            try {
                const imagesUrl = await Promise.all(qualificationData.certificates.map(async (file) => uploadFile(file)));
                const submissionData = {
                    ...qualificationData,
                    images: imagesUrl,
                };

                await addQualification(coachId, submissionData);
                setOpenSuccess(true); // Assuming you have a state to control the success notification
                // Reset form and state after successful submission
                setQualificationData(initialQualificationData);
            } catch (error) {
                console.error('Error adding qualification:', error);
                setSnackData((prev) => ({ ...prev, isSuccess: false }));
            } finally {
                setIsLoading(false);
                handleClose();

                // Fetch and update qualifications data
                const qualificationsData = await getCoachQualifications(coachId);
                setQualifications(qualificationsData);
                setSnackData((prev) => ({ ...prev, isOpen: true }));
            }
        } else {
            console.log('Validation errors', errors);
            // Optionally handle focus management to the first error field here
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCertificatePreview('');
        resetForm();
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
        setUploadedImages(null);
        handleCloseMenu();
    };

    const handleOpenMenu = (event, id, programName, qualification, date, images) => {
        event.preventDefault();
        setEditingQualification({ anchor: event.currentTarget, id, programName, qualification, date, images });
    };

    const handleCloseMenu = () => {
        setEditingQualification(null);
    };


    const handleConfirmDelete = async () => {
        if (selectedQualificationIdForDelete) {
            try {
                await deleteQualification(coachId, selectedQualificationIdForDelete);
                // Updates qualifications . . .
                const qualificationsData = await getCoachQualifications(coachId);
                setQualifications(qualificationsData);
            } catch (error) {
                console.error('Error deleting qualification:', error);
            }

            handleCloseDeleteDialog();
            handleCloseMenu();
        }
    };

    const handleCloseDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
    };

    function generatePayloadFromData(data) {
        return {
            programName: data.get('programName'),
            qualification: data.get('qualification'),
            date: data.get('date'),
            images: data.get('images'),
        };
    }

    async function handleSubmitEditQualification(event) {
        event.preventDefault();
        event.stopPropagation();

        const data = new FormData(event.currentTarget);

        setError(null);

        if (!data.get('programName')) {
            setError(t('Upload the Program Name'));
            return;
        }

        if (!data.get('qualification')) {
            setError(t('Enter the qualification'));
            return;
        }

        if (!data.get('date')) {
            setError(t('Enter the Date'));
            return;
        }

        let imagesToUpload = editingQualification.images;
        console.log(uploadedImages)
        if (uploadedImages && uploadedImages.length) {
            imagesToUpload = await Promise.all(uploadedImages.map(async (file) => {
                if (file instanceof File) return uploadFile(file);
                if (file instanceof Object) return file.preview;
                return file;
            }));
        }

        
        const payload = generatePayloadFromData(data);
        payload.images = imagesToUpload;
        payload.updated_at = Timestamp.now();

        editCoachQualificationById(coachId, editingQualification.id, payload)
            .then(async () => {
                handleCloseEdit();
                handleCloseMenu();
                const qualificationsData = await getCoachQualifications(coachId);
                setQualifications(qualificationsData);
            })
            .catch((error) => setError(error.message))
            .finally(() => setIsLoading(false));
    }

    const navigateToImagePreview = (imageUrl) => {
        window.open(`/image-preview/${encodeURIComponent(imageUrl)}`, '_blank');
    };

    const openCertifDialog = (images) => {
        setImages(images);
        setIsCertifDialogOpen(true);
    }

    const handleCloseCertifDialog = () => {
        setIsCertifDialogOpen(false);
    }

    return (
        <Card>
            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <UserListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD2} onRequestSort={handleRequestSort} />
                        <TableBody>
                            {qualifications.map((row) => {
                                const { id, programName, qualification, date, images, image } = row;

                                return (
                                    <TableRow hover key={id} tabIndex={-1} role="checkbox">
                                        <TableCell align="left">
                                            {programName}
                                        </TableCell>
                                        <TableCell align="left">
                                            {qualification}
                                        </TableCell>
                                        <TableCell align="left">
                                            {t(date)}
                                        </TableCell>

                                        <TableCell align="left">
                                            <Button variant="outlined" size="small" onClick={() => openCertifDialog(image ? [image] : images)}>{t('View certificates')}</Button>
                                        </TableCell>

                                        <TableCell align="right">
                                            <IconButton
                                                size="large"
                                                color="inherit"
                                                onClick={(event) => handleOpenMenu(event, id, programName, qualification, date, image ? [image] : images)}
                                            >
                                                <Iconify icon={'eva:more-vertical-fill'} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Scrollbar>
            <Button
                variant="contained"
                onClick={handleClickOpen}
                sx={{ marginTop: '1rem', marginBottom: '1rem', width: 'fit-content', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
            >
                {t('Add Qualification')}
            </Button>

            <div>
                <Dialog open={open} onClose={handleClose}>
                    <DialogContent sx={{ padding: '37px 43px' }}>
                        <form ref={formRef} onSubmit={handleSave}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography variant="h6" gutterBottom sx={{ marginBottom: '40px' }}>
                                    {t('Add Qualification')}
                                </Typography>
                            </Box>

                            <Stack spacing={2}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="programName"
                                    label={t('Name of the program')}
                                    type="text"
                                    fullWidth
                                    placeholder="Name"
                                    variant="outlined"
                                    value={qualificationData.programName}
                                    onChange={handleChange}
                                    InputProps={{
                                        style: { fontWeight: 'normal' },
                                    }}
                                    error={!!formErrors.programName}
                                    helperText={formErrors.programName || ''}
                                />

                                <TextField
                                    margin="dense"
                                    name="qualification"
                                    label={t('Qualification')}
                                    type="text"
                                    fullWidth
                                    placeholder="Qualification"
                                    variant="outlined"
                                    value={qualificationData.qualification}
                                    onChange={handleChange}
                                    InputProps={{
                                        style: { fontWeight: 'normal' },
                                    }}
                                    error={!!formErrors.qualification}
                                    helperText={formErrors.qualification || ''}
                                />

                                <Box sx={{ marginBottom: 4 }}>
                                    <TextField
                                        margin="dense"
                                        name="date"
                                        label={t('Date')}
                                        type="date"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={qualificationData.date}
                                        onChange={handleChange}
                                        error={!!formErrors.date}
                                        helperText={formErrors.date || ''}
                                        inputProps={{
                                            max: new Date().toISOString().split('T')[0], // Set the max date to today
                                        }}
                                    />

                                    <FormHelperText sx={{ marginTop: 0 }}>{t('Enter the Date of Receipt')}</FormHelperText>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        width: '100%',
                                    }}
                                >
                                    {/* <Button
                                        variant="contained"
                                        component="label"
                                        sx={{
                                            width: '100%',
                                            height: '45px',
                                            backgroundColor: 'grey',
                                            marginRight: '25',
                                            marginLeft: '25',
                                            ':hover': {
                                                backgroundColor: 'lightgrey',
                                            },
                                            mb: 2,
                                        }}
                                    >
                                        {t('Upload')}
                                        <input type="file" hidden onChange={handleFileChange} />
                                    </Button> */}
                                    <FileUpload onChange={handleFileChange} multi/>
                                    {formErrors.certificate && <FormHelperText >{formErrors.certificate}</FormHelperText>}
                                </Box>
                            </Stack>

                            <DialogActions sx={{ justifyContent: 'space-between', marginTop: '35px' }}>
                                <LoadingButton
                                    onClick={handleClose}
                                    color="error"
                                    variant="outlined"
                                    style={{
                                        border: '1.5px solid #E24B29',
                                        width: '150px',
                                        height: '40px',
                                        fontWeight: '300',
                                    }}
                                >
                                    {t('Cancel')}
                                </LoadingButton>

                                <LoadingButton
                                    loading={isLoading}
                                    disabled={isLoading}
                                    loadingIndicator={
                                        <CircularProgress role="progressbar" color="primary" size={16} />
                                    }
                                    type="submit"
                                    sx={{
                                        width: '150px',
                                        height: '40px',
                                        backgroundColor: '#48B5BD',
                                        color: 'white',
                                        ':hover': { backgroundColor: 'darkturquoise', color: 'white' },
                                        fontWeight: '300',
                                    }}
                                >
                                    {t('Save')}
                                </LoadingButton>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>

                {/* Edit . . . */}
                <Dialog open={openEdit} onClose={handleCloseEdit}>
                    <DialogContent sx={{ padding: '2rem 1.5rem' }}>
                        <StyledRoot>
                            <Container maxWidth="sm">
                                <StyledContent>
                                    <Typography variant="h4" sx={{ marginBottom: 4 }} gutterBottom>
                                        {`${t('Edit qualification')} | ${editingQualification?.programName || ""} `}
                                    </Typography>
                                    <form onSubmit={handleSubmitEditQualification}>
                                        <Stack spacing={3} mb={3}>
                                            {/* {editingQualification?.programName && ( */}
                                            <>
                                                <TextField
                                                    name="programName"
                                                    label={t('Program Name')}
                                                    defaultValue={editingQualification?.programName}
                                                />
                                                <TextField
                                                    name="qualification"
                                                    label={t('Qualification')}
                                                    defaultValue={editingQualification?.qualification}
                                                />
                                                <TextField name="date" label={t('Date')} defaultValue={editingQualification?.date} />
                                            </>
                                            {/* )} */}
                                            <FileUpload defaultFiles={editingQualification?.images} maxSize={10} multi onChange={setUploadedImages} />
                                            
                                        </Stack>

                                        {error && <Alert severity="error">{error}</Alert>}
                                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleCloseEdit();
                                                }}
                                                variant="outlined"
                                                disabled={isLoading}
                                            >
                                                {t('Cancel')}
                                            </Button>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                loading={isLoading}
                                                disabled={isLoading}
                                            >
                                                {t('Update')}
                                            </Button>
                                        </Stack>
                                    </form>
                                </StyledContent>
                            </Container>
                        </StyledRoot>
                    </DialogContent>
                </Dialog>

                <Popover
                    open={Boolean(editingQualification)}
                    anchorEl={editingQualification?.anchor}
                    onClose={handleCloseMenu}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    PaperProps={{
                        sx: {
                            p: 1,
                            width: 140,
                            '& .MuiMenuItem-root': {
                                px: 1,
                                typography: 'body2',
                                borderRadius: 0.75,
                            },
                        },
                    }}
                >
                    <Button onClick={() => setOpenEdit(true)}>
                        <MenuItem sx={{ width: 150, display: 'flex', alignItems: 'center' }}>
                            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                            {t('Edit')}
                        </MenuItem>
                    </Button>

                    <Button onClick={() => handleOpenDeleteDialog(editingQualification?.id)}>
                        <MenuItem sx={{ width: 140, display: 'flex', alignItems: 'center', color: 'error.main' }}>
                            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                            {t('Delete')}
                        </MenuItem>
                    </Button>
                </Popover>

                {/* Delete Confirmation Dialog */}
                <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
                    <DialogTitle>{t('Confirm Delete')}</DialogTitle>
                    <DialogContent>{t('Are you sure you want to delete this Data?')}</DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteDialog}>{t('Cancel')}</Button>
                        <Button onClick={handleConfirmDelete} color="secondary">
                            {t('Delete')}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Certifications dialog */}
                <Dialog open={isCertifDialogOpen} onClose={handleCloseCertifDialog}>
                    <DialogTitle>{t('Certificates')}</DialogTitle>
                    <DialogContent>
                        <Grid2 container spacing={2} gridTemplateColumns={3}>
                            {
                                images.length === 0
                                    ? <Box>{t("Empty")}</Box>
                                    : images.length === 1
                                        ? <Grid2>
                                            <Button type="button" onClick={() => navigateToImagePreview(image)} style={{ cursor: "pointer" }}>
                                                <img src={formatImage(images[0], {h: 400})} alt="Certificate" style={{ height: 'auto' }} />
                                            </Button>
                                        </Grid2>
                                        : images.map((image) => (
                                            <Grid2 xs={6} md={4} key={image}>
                                                <Button type="button" onClick={() => navigateToImagePreview(image)} style={{ cursor: "pointer" }}>
                                                    <img src={formatImage(image, {h: 400})} alt="Certificate" style={{ height: 'auto' }} />
                                                </Button>
                                            </Grid2>
                                        ))
                            }
                        </Grid2>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseCertifDialog}>{t('Close')}</Button>
                    </DialogActions>
                </Dialog>

                <Notification
                    open={snackData.isOpen}
                    autoHideDuration={5000}
                    success={snackData.isSuccess}
                    message={t(snackData.isSuccess ? 'Item has been successfuly saved' : 'Data was not saved! Please try again')}
                    onClose={handleCloseSnack}
                    marginTop={80}
                />
            </div>
        </Card>
    );
}

export default Qualifications;