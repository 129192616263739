import {useEffect, useState} from "react";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../firebaseConfig";

export const useFetchItem = (collectionName, itemId) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!collectionName || !itemId) {
            setIsLoading(false);
            return;
        }
        const fetchData = async () => {
            const docRef = doc(db, collectionName, itemId);
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
                setData(docSnapshot.data())
            } else {
                setData(null);
            }
        };
        fetchData().catch(setError).finally(() => setIsLoading(false));
    }, [collectionName, itemId]);

    return {data, isLoading, error};
};