import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-chat-elements/dist/main.css';
import { Grid, Box, TextField, Button } from '@mui/material';
import { MessageList, ChatList } from 'react-chat-elements';
import { collection, query, getDoc, doc, onSnapshot } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { useFetchUserChats, sendMessage } from '../services/chats_service';
import UserContext from '../contexts/UserContext';
import { db } from '../firebaseConfig';
import { formatImage } from '../utils/formatImage';
import Iconify from '../components/iconify';

const ChatsPage = () => {
  const { user } = useContext(UserContext);
  const { chats } = useFetchUserChats(user?.id);
  const { id: paramsId } = useParams();
  const [currentChat, setCurrentChat] = useState(null);
  const [otherUser, setOtherUser] = useState({});
  const [messagesForChat, setMessagesForChat] = useState([]);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const { t } = useTranslation();

  const toRender = chats.map((chat) => ({
    ...chat,
    avatar: formatImage(chat.image, { w: 72, h: 72 }),
    alt: chat.name,
    title: chat.name,
    subtitle: chat.lastMessage,
    date: chat.lastUpdate ? new Date(chat.lastUpdate.toMillis()) : new Date(),
  }));

  useEffect(() => {
    if (chats && chats.length > 0 && !currentChat) {
      if (paramsId) {
        const targetChat = chats.find((chat) => chat.id === paramsId);
        setCurrentChat(targetChat);
      } else {
        setCurrentChat(chats[0]);
      }
    }
  }, [chats, currentChat, paramsId]);

  useEffect(() => {
    if (!currentChat) return;

    const otherUserId = (currentChat.members ?? []).find((userId) => userId !== user?.id);
    if (!otherUserId) return;

    const docRef = doc(db, 'users', otherUserId);
    getDoc(docRef).then((docSnapshot) => {
      if (docSnapshot.exists()) {
        setOtherUser(docSnapshot.data());
      } else {
        setOtherUser(null);
      }
    });
  }, [currentChat, user]);

  function handleClick(chat) {
    setCurrentChat(chat);
  }

  function onSendMessage(event) {
    event.preventDefault();
    if (!currentChat || !user) {
      return;
    }
    const currentChatOtherUserId = currentChat.members.find((m) => m !== user.id);
    if (!currentChatOtherUserId) return;
    sendMessage(message, currentChat.id, user.id, currentChatOtherUserId);
    setMessage('');
  }

  const handleTitleClick = (message) => {
    navigate(`/users/${message.senderId}`);
  };

  function sortByCreatedAt(a, b) {
    return a.createdAt.toMillis() - b.createdAt.toMillis();
  }

  function processMessagesToRender(messages) {
    return messages
      .map((message) => ({
        ...message,
        position: message.senderId === user?.id ? 'right' : 'left',
        type: 'text',
        title: message.senderId === user?.id ? user?.name : otherUser?.name,
        text: message?.message,
      }))
      .sort(sortByCreatedAt);
  }

  useEffect(() => {
    if (!currentChat) {
      return;
    }

    const currentChatOtherUserId = currentChat.members.find((m) => m !== user.id);

    if (!currentChatOtherUserId) return;

    const q = query(collection(db, 'chats', user.id, 'chats', currentChatOtherUserId, 'messages'));
    onSnapshot(q, (querySnapshot) => {
      const messages = [];
      querySnapshot.forEach((doc) => {
        messages.push(doc.data());
      });
      setMessagesForChat(messages);

      return null;
    });
  }, [currentChat]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      if (message.trim() !== '') {
        try {
          const currentChatOtherUserId = currentChat.members.find((m) => m !== user.id);
          if (!currentChatOtherUserId) return;
          sendMessage(message, currentChat.id, user.id, currentChatOtherUserId);
          setMessage('');
        } catch (error) {
          console.error('Error sending message:', error);
        }
      }
    }
  };

  return !chats.length ? (
    <Box height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="2rem">
      <div style={{ fontSize: '2rem' }}>{t('No chat sessions')}</div>
      <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} href={'/dashboard/sessions/create'}>
        {t('New Message')}
      </Button>
    </Box>
  ) : (
    <>
      <Helmet>
        <title> {t('chats')}</title>
      </Helmet>
      <Grid container sx={{ height: '100%' }}>
        <Grid xs={4} sx={{ position: 'relative', overflow: 'auto' }}>
          <div style={{ display: 'flex', alignItems: 'row', position: 'absolute', width: '100%' }}>
            <div style={{ flex: 1, width: '100%' }}>
              <ChatList className="chat-list" dataSource={toRender} onClick={(chat) => handleClick(chat)} />
            </div>

            <div style={{ height: '100%', backgroundColor: 'lightgray', width: 1, marginLeft: '8px' }} />
          </div>
        </Grid>

        <Grid xs={8}>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ flex: 1, overflow: 'auto' }}>
              <MessageList
                className="message-list"
                lockable
                toBottomHeight={'100%'}
                dataSource={processMessagesToRender(messagesForChat)}
                onTitleClick={handleTitleClick}
              />
            </div>

            <div style={{ display: 'flex', marginTop: '8px' }}>
              <TextField
                sx={{ flex: 1, marginRight: '8px' }}
                autofocus
                multiline
                placeholder={t('Type here...')}
                id="send-message"
                label={t('Send message')}
                variant="outlined"
                value={message}
                onChange={(event) => setMessage(event?.target?.value)}
                onKeyDown={handleKeyDown}
              />

              <Button variant="contained" onClick={(message) => onSendMessage(message)}>
                {t('Send')}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ChatsPage;
