import { useState } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import {
  Stack,
  MenuItem,
  Card,
  Popover,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Typography,
  Container,
  LinearProgress,
  Avatar,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Iconify from '../components/iconify';
import { fDateTime } from '../utils/formatTime';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { useFetchItem } from '../services/item_fetch_service';
import { formatImage } from '../utils/formatImage';
import { UserListHead } from '../sections/@dashboard/user';
import { useFetchAllPrograms, useFetchUserPrograms } from '../services/programs_service';
import { useFetchCoachSessions } from '../services/sessions_service';
import i18n from '../i18n';
// ----------------------------------------------------------------------

export default function UserDetailsPage() {
  const { id, userId } = useParams();
  const { t } = useTranslation();
  const { data: user, isLoading } = useFetchItem('users', id);
  console.log(user);

  return (
    <>
      <Helmet>
        <title>
          {t('user_details')} | {user?.name ?? ''}
        </title>
      </Helmet>
      {isLoading && <LinearProgress />}
      {!isLoading && (
        <Container>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: 16,
              height: 480,
              overflow: 'hidden',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              backgroundAttachment: 'scroll, scroll',
              backgroundImage: `linear-gradient(rgba(22, 28, 36, 0.64) 0%, rgba(22, 28, 36, 0.64) 100%), url(${formatImage(
                user?.page_image ?? user?.thumbnail ?? user?.wide_image ?? user?.image,
                { h: 480 }
              )})`,
              backgroundOrigin: 'padding-box, padding-box',
              backgroundClip: 'border-box, border-box',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
            }}
          >
            <Typography color={'white'} variant="h4" sx={{ mb: 5, flex: 1 }}>
              {t('user_details')} | {user?.name}
            </Typography>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={5}
            >
              {/* Display relevant sections for coach details */}

              <UserSection id={id} />
            </Stack>
          </div>
        </Container>
      )}

      {!isLoading && (
        <Container>
          <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>
            {t('Programs')}
          </Typography>
          {<ProgramSection userId={id} />}
          <div style={{ width: '100%', height: '1px', backgroundColor: 'lightgray', marginTop: '2.5rem' }} />
          {/* <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>
            {t('Sessions')}
          </Typography>
          {<SessionSection userId={id} />} */}
        </Container>
      )}
    </>
  );
}

function UserSection({ id }: { id: string }) {
  const { data: user, isLoading } = useFetchItem('users', id);

  if (isLoading || !user) {
    return <div />;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        alt={user.name}
        src={formatImage(user.image, { w: 128, h: 128 })}
        sx={{ width: 64, height: 64, marginRight: '16px' }}
      />

      <div>
        <Typography color={'white'} variant="body1" sx={{ marginBottom: '4px' }}>
          {user.name}
        </Typography>
        <Typography color={'white'} variant="body2">
          {user.city}, {user.country}
        </Typography>
        <Typography color={'white'} variant="body2">
          {user.email} | {user.phone}
        </Typography>
      </div>
    </div>
  );
}

function SessionSection({ userId }: { userId: string }) {
  const { sessions, isLoading } = useFetchCoachSessions(userId);
  const { t } = useTranslation();

  const [editingSession, setEditingSession] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const handleOpenMenu = (event, id) => {
    setEditingSession({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setEditingSession(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (isLoading || !sessions) return <div />;

  if (sessions && sessions.length === 0)
    return (
      <Typography variant="body2" sx={{ mb: 5 }}>
        {t('No Sessions')}
      </Typography>
    );

  return (
    <Card>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onRequestSort={handleRequestSort} />
            <TableBody>
              {sessions.map((row) => {
                const { id, name, image, sessionType, scheduledFor } = row;

                return (
                  <TableRow hover key={id} tabIndex={-1} role="checkbox">
                    <TableCell component="th" scope="row">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar alt={name} src={image} />
                        <Typography variant="subtitle2" noWrap>
                          {name}
                        </Typography>
                      </Stack>
                    </TableCell>

                    <TableCell align="left">{t(sessionType)}</TableCell>

                    <TableCell align="left">{fDateTime(scheduledFor.toMillis())}</TableCell>
                  </TableRow>
                );
              })}
              {sessions.length === 0 && (
                <TableRow style={{ height: 53 }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}

function getProgramById(programList, programId) {
  const foundUser = programList.find((program) => program.id === programId);
  return foundUser || null;
}

function ProgramSection() {
  const { id } = useParams();
  const { userPrograms, isLoading } = useFetchUserPrograms(id);
  const { programs, loading } = useFetchAllPrograms();
  const { t } = useTranslation();
  const [editingProgram, setEditingProgram] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  // const handleOpenMenu = (event, id) => {
  //   setEditingProgram({ anchor: event.currentTarget, id });
  // };

  // const handleCloseMenu = () => {
  //   setEditingProgram(null);
  // };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (isLoading || !userPrograms) return <div />;

  if (userPrograms && userPrograms.length === 0)
    return (
      <Typography variant="body2" sx={{ mb: 5 }}>
        {t('No Programs')}
      </Typography>
    );

  return (
    <Card>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD3} onRequestSort={handleRequestSort} />
            <TableBody>
              {userPrograms.map((row) => {
                const { id, program_id: programId } = row;

                return (
                  <TableRow hover key={id} tabIndex={-1} role="checkbox">
                    <TableCell component="th" scope="row">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar alt={'image'} src={getProgramById(programs, programId)?.thumbnail} />
                        <Typography variant="subtitle2" noWrap>
                          {getProgramById(programs, programId)?.name}
                        </Typography>
                      </Stack>
                    </TableCell>

                    <TableCell align="left">{getProgramById(programs, programId)?.description}</TableCell>
                    <TableCell align="left">{getProgramById(programs, programId)?.package}</TableCell>
                    <TableCell align="left">{getProgramById(programs, programId)?.numberOfParticipants}</TableCell>
                    <TableCell align="left">{getProgramById(programs, programId)?.rating}</TableCell>
                    <TableCell align="left">{getProgramById(programs, programId)?.tags}</TableCell>
                  </TableRow>
                );
              })}
              {userPrograms.length === 0 && (
                <TableRow style={{ height: 53 }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}

const TABLE_HEAD = [
  { id: 'name', label: i18n.t('Name'), alignRight: false },
  { id: 'sessionType', label: i18n.t('Session Type'), alignRight: false },
  { id: 'scheduledFor', label: i18n.t('Scheduled For'), alignRight: false },
  {},
];

const TABLE_HEAD3 = [
  { id: 'name', label: i18n.t('Name'), alignRight: false },
  { id: 'description', label: i18n.t('description'), alignRight: false },
  { id: 'Packages', label: i18n.t('Packages'), alignRight: false },
  { id: 'Participants', label: i18n.t('Participants'), alignRight: false },
  { id: 'Rating', label: i18n.t('Rating'), alignRight: false },
  { id: 'Tags', label: i18n.t('Tags'), alignRight: false },
  {},
];
