export default function toProperCase(str) {
	return str
		.split(' ')
		.map(word => {
			const words = word.split('-');
			const capitalizedWords = words.map((w, index) => {
				if (index === 0)
					return w.charAt(0).toUpperCase() + w.slice(1).toLowerCase();
				return w.charAt(0) + w.slice(1).toLowerCase();
			});
			return capitalizedWords.join('-');
		})
		.join(' ');
}
