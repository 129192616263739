import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState } from 'react';
// @mui
import {
  Card,
  Table,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Paper,
  Avatar,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  IconButton,
  MenuItem,
  Popover,
} from '@mui/material';
// components
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
// mock
import { useFetchAllUsers, deleteUser } from '../services/users_service';
import Iconify from '../components/iconify/Iconify';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: i18n.t('Name'), alignRight: false },
  { id: 'city', label: i18n.t('City'), alignRight: false },
  { id: 'email', label: i18n.t('Email'), alignRight: false },
  { id: 'phone', label: i18n.t('Phone'), alignRight: false },
  {},
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UsersPage() {
  const [filterName, setFilterName] = useState('');
  const { users, reloadUsers } = useFetchAllUsers();

  const [editingUser, setEditingUser] = useState(null);
  const [editingCoach, setEditingCoach] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedUserIdForDelete, setSelectedUserIdForDelete] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleOpenMenu = (event, id) => {
    setEditingUser({ anchor: event.currentTarget, id });
    setEditingCoach({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setEditingUser(null);
    setEditingCoach(null);
  };

  const handleOpenDeleteDialog = (id) => {
    setSelectedUserIdForDelete(id);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedUserIdForDelete) {
      try {
        // Call the deleteCoach function passing the selectedCoachIdForDelete
        await deleteUser(selectedUserIdForDelete);
        reloadUsers();
      } catch (error) {
        console.error('Error deleting coach:', error);
      }

      handleCloseDeleteDialog();
      handleCloseMenu();
    }
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  function navigateToUserDetailsPage(id) {
    navigate(`/dashboard/users/${id}`);
  }

  return (
    <>
      <Helmet>
        <title> {t('User')}</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('users')}
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} href={''}>
            {t('New User')}
          </Button> */}
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name, city, email, image, phone } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={selectedUser}
                        style={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          padding="none"
                          onClick={() => navigateToUserDetailsPage(id)}
                        >
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={image} />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left" onClick={() => navigateToUserDetailsPage(id)}>
                          {city}
                        </TableCell>

                        <TableCell align="left" onClick={() => navigateToUserDetailsPage(id)}>
                          {email}
                        </TableCell>
                        <TableCell align="left" onClick={() => navigateToUserDetailsPage(id)}>
                          {phone}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            {t('Not found')}
                          </Typography>

                          <Typography variant="body2">
                            {t('No results found for')} &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> {t('Try checking for typos or using complete words')}
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('Row per page:')}
          />
          <Popover
            open={Boolean(editingUser)}
            anchorEl={editingUser?.anchor}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <Button href={`/dashboard/users/${editingUser?.id}/edit`}>
              <MenuItem sx={{ width: 150, display: 'flex', alignItems: 'center' }}>
                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                {t('Edit')}
              </MenuItem>
            </Button>

            <Button onClick={() => handleOpenDeleteDialog(editingUser?.id)}>
              <MenuItem sx={{ width: 140, display: 'flex', alignItems: 'center', color: 'error.main' }}>
                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                {t('Delete')}
              </MenuItem>
            </Button>
          </Popover>
          {/* Delete Confirmation Dialog */}
          <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
            <DialogTitle>{t('Confirm Delete')}</DialogTitle>
            <DialogContent>{t('Are you sure you want to delete this User?')}</DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteDialog}>{t('Cancel')}</Button>
              <Button onClick={handleConfirmDelete} color="secondary">
                {t('Delete')}
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </Container>
    </>
  );
}
