import { Timestamp, collection, getDocs, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';

function formatDate(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}

function generateLastDates(initialDate, nbDays) {
  const list = [];

  for (let i = 0; i < nbDays; i += 1) {
    const currentDate = new Date(initialDate);
    currentDate.setDate(currentDate.getDate() - i);
    list.unshift(formatDate(currentDate));
  }
  return list;
}

export const useFetchItemDailyCount = (collectionName, fieldName = 'created_at', nbDays = 7, conditions = []) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const now = new Date();
  const startDate = new Date(now.getTime() - nbDays * 24 * 60 * 60 * 1000);
  startDate.setHours(0, 0, 0, 0);

  const queryDate = Timestamp.fromDate(startDate);
  const previousDates = generateLastDates(now, nbDays);

  useEffect(() => {
    if (!collectionName) {
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      const whereClauses = conditions.map((cond) => where(cond[0], cond[1], cond[2]));
      const itemsRef = collection(db, collectionName);
      const itemsQuery = query(itemsRef, where(fieldName, '>', queryDate), ...whereClauses);

      const snapshot = await getDocs(itemsQuery);
      const items = snapshot.docs.map((doc) => doc.data());

      const dailyCounts = items.reduce(
        (counts, user) => {
          const index = previousDates.indexOf(formatDate(user[fieldName].toDate()));
          if (index !== -1) counts[index] += 1;
          return counts;
        },
        Array.from({ length: nbDays }, () => 0)
      );

      setData({
        dates: previousDates,
        counts: dailyCounts,
      });
    };

    fetchData()
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, [collectionName, nbDays, fieldName]);

  return { data, isLoading, error };
};

export const useFetchItemDailyCount2 = (
  collectionName,
  fieldName = 'created_at',
  nbDays = 7,
  conditions = [],
  programId
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const now = new Date();
  const startDate = new Date(now.getTime() - nbDays * 24 * 60 * 60 * 1000);
  startDate.setHours(0, 0, 0, 0);

  const queryDate = Timestamp.fromDate(startDate);
  const previousDates = generateLastDates(now, nbDays);

  useEffect(() => {
    if (!collectionName) {
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const whereClauses = conditions.map((cond) => where(cond[0], cond[1], cond[2]));
        const itemsRef = collection(db, collectionName);

        // Add the programId filter condition if provided
        const programIdFilter = programId ? where('program_id', '==', programId) : [];

        const itemsQuery = query(itemsRef, where(fieldName, '>', queryDate), ...programIdFilter, ...whereClauses);

        const snapshot = await getDocs(itemsQuery);
        const items = snapshot.docs.map((doc) => doc.data());

        const dailyCounts = items.reduce(
          (counts, user) => {
            const index = previousDates.indexOf(formatDate(user[fieldName].toDate()));
            if (index !== -1) counts[index] += 1;
            return counts;
          },
          Array.from({ length: nbDays }, () => 0)
        );

        setData({
          dates: previousDates,
          counts: dailyCounts,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [collectionName, nbDays, fieldName, programId]);

  return { data, isLoading, error };
};
