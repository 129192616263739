import { useEffect, useState } from 'react';
import {
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../firebaseConfig';
import { useFetchItemDailyCount } from './item_daily_count_fetch_service';
import toProperCase from '../utils/toProperCase';

export async function createProgram(payload, sessions, packages) {
  const programId = uuidv4();
  payload.id = programId;

  await setDoc(doc(db, 'programs', programId), payload);
  await Promise.all(
    sessions.map((session) => setDoc(doc(db, 'programs', programId, 'sessions_type', session.id), session))
  );
  await Promise.all(packages.map((pack) => setDoc(doc(db, 'programs', programId, 'packages', pack.id), pack)));
}

export async function updateProgram(programId, payload, sessions = [], packages = []) {
  const existingSessionsSnap = await getDocs(query(collection(db, 'programs', programId, 'sessions_type')));
  const existingPackagesSnap = await getDocs(query(collection(db, 'programs', programId, 'packages')));

  const existingSessionsIds = existingSessionsSnap.docs.map((doc) => doc.id);
  const existingPackagesIds = existingPackagesSnap.docs.map((doc) => doc.id);

  const toDeleteSessions = existingSessionsIds.filter((id) => !sessions.find((s) => s.id === id));
  const toCreateSessions = sessions.filter((s) => !existingSessionsIds.includes(s.id));
  const toUpdateSessions = sessions.filter((s) => existingSessionsIds.includes(s.id));

  const toDeletePackages = existingPackagesIds.filter((id) => !packages.find((p) => p.id === id));
  const toCreatePackages = packages.filter((p) => !existingPackagesIds.includes(p.id));
  const toUpdatePackages = packages.filter((p) => existingPackagesIds.includes(p.id));

  const sessionPromises = [
    ...toDeleteSessions.map((id) => deleteDoc(doc(db, 'programs', programId, 'sessions_type', id))),
    ...toCreateSessions.map((session) => setDoc(doc(db, 'programs', programId, 'sessions_type', session.id), session)),
    ...toUpdateSessions.map((session) =>
      updateDoc(doc(db, 'programs', programId, 'sessions_type', session.id), session)
    ),
  ];

  const packagePromises = [
    ...toDeletePackages.map((id) => deleteDoc(doc(db, 'programs', programId, 'packages', id))),
    ...toCreatePackages.map((pack) => setDoc(doc(db, 'programs', programId, 'packages', pack.id), pack)),
    ...toUpdatePackages.map((pack) => updateDoc(doc(db, 'programs', programId, 'packages', pack.id), pack)),
  ];

  await Promise.all([...sessionPromises, ...packagePromises]);
  return updateDoc(doc(db, 'programs', programId), payload);
}

export async function deleteProgram(programId) {
  const deleteResult = await deleteDoc(doc(db, 'programs', programId));
  const packagesSnapshot = await getDocs(query(collection(db, 'programs', programId, 'packages')));
  const sessionsSnapshot = await getDocs(query(collection(db, 'programs', programId, 'sessions_type')));
  const packages = packagesSnapshot.docs.map((doc) => doc.data());
  const sessions = sessionsSnapshot.docs.map((doc) => doc.data());
  await Promise.all(
    sessions.map(async (session) => {
      await deleteDoc(doc(db, 'programs', programId, 'sessions_type', session.id));
    })
  );
  await Promise.all(
    packages.map(async (pack) => {
      await deleteDoc(doc(db, 'programs', programId, 'packages', pack.id));
    })
  );

  return deleteResult;
}

export const useFetchAllPrograms = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [programs, setPrograms] = useState([]);
  const [error, setError] = useState(null);

  const reloadPrograms = () => {
    const fetchData = async () => {
      const programsRef = collection(db, 'programs');
      const querySnapshot = await getDocs(programsRef);
      const _programs = querySnapshot.docs.map((doc) => doc.data());

      const completePrograms = await Promise.all(
        _programs.map(async (program) => {
          const programId = program.id;

          const sessionsRef = collection(db, 'programs', programId, 'sessions');
          const sessionsSnapshot = await getDocs(sessionsRef);
          const sessions = sessionsSnapshot.docs.map((doc) => doc.data());

          const packagesRef = collection(db, 'programs', programId, 'packages');
          const packagesSnapshot = await getDocs(packagesRef);
          const packages = packagesSnapshot.docs.map((doc) => doc.data());

          return {
            ...program,
            name: program.name,
            sessions,
            packages,
          };
        })
      );

      setPrograms(completePrograms);
    };

    fetchData()
      .catch(setError)
      .finally(() => setIsLoading(false));
  };

  useEffect(reloadPrograms, []);

  return { programs, isLoading, reloadPrograms, error };
};

export const useCountPrograms = (coachId = null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const collectionName = 'programs';
      const programsRef = collection(db, collectionName);
      const programsQuery = query(programsRef, where('coaches_id', 'array-contains', coachId));
      const snapshot = await getCountFromServer(coachId ? programsQuery : programsRef);
      setCount(snapshot.data().count);
    };

    fetchData()
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, []);

  return { count, isLoading, error };
};

export const useCountProgReg = (coachId) => {
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [error, setError] = useState(null);
  const { programs, isLoading: isLoadingPrograms } = useFetchCoachPrograms(coachId);

  useEffect(() => {
    if (!coachId || isLoadingPrograms) {
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      const progRegRef = collection(db, 'programs_registration');
      const queries = programs.map((program) =>
        getCountFromServer(query(progRegRef, where('program_id', '==', program.id)))
      );
      const snapshots = await Promise.all(queries);
      const progRegCount = snapshots.reduce((count, snap) => count + snap.data().count, 0);
      setCount(progRegCount);
    };

    fetchData()
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, [programs]);

  return { count, isLoading, error };
};

export const useFetchCoachPrograms = (coachId) => {
  const [isLoading, setIsLoading] = useState(true);
  const [programs, setPrograms] = useState([]);
  const [error, setError] = useState(null);

  const reloadPrograms = () => {
    if (!coachId) {
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      const collectionName = 'programs';

      const programsRef = collection(db, collectionName);
      const q = query(programsRef, where('coaches_id', 'array-contains', coachId));
      const querySnapshot = await getDocs(q);
      const _programs = querySnapshot.docs.map((doc) => doc.data());

      const completePrograms = await Promise.all(
        _programs.map(async (program) => {
          const programId = program.id;

          const sessionsRef = collection(db, 'programs', programId, 'sessions');
          const sessionsSnapshot = await getDocs(sessionsRef);
          const sessions = sessionsSnapshot.docs.map((doc) => doc.data());

          const packagesRef = collection(db, 'programs', programId, 'packages');
          const packagesSnapshot = await getDocs(packagesRef);
          const packages = packagesSnapshot.docs.map((doc) => doc.data());

          return {
            ...program,
            name: program.name,
            sessions,
            packages,
          };
        })
      );

      setPrograms(completePrograms);
    };

    fetchData()
      .catch(setError)
      .finally(() => setIsLoading(false));
  };

  useEffect(reloadPrograms, [coachId]);

  return { programs, isLoading, reloadPrograms, error };
};

export const useFetchProgram = (programId) => {
  const [isLoading, setIsLoading] = useState(true);
  const [program, setProgram] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProgram = async () => {
      try {
        if (!programId) return;

        const programSnapShot = await getDoc(doc(db, 'programs', programId));
        const packagesSnapShot = await getDocs(collection(db, 'programs', programId, 'packages'));
        const sessionsSnapShot = await getDocs(collection(db, 'programs', programId, 'sessions_type'));

        const programData = programSnapShot.data();
        const _packagesData = packagesSnapShot.docs.map((doc) => doc.data());
        const sessionsData = sessionsSnapShot.docs.map((doc) => doc.data());

        const packagesData = _packagesData.map((pack) => {
          return {
            ...pack,
            total: pack.content.reduce(
              (acc, el) => acc + sessionsData.find((s) => s.id === el.session_type_id).price * el.count,
              0
            ),
          };
        });
        setProgram({ ...programData, packages: packagesData, sessions: sessionsData });
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProgram();
  }, [programId]);

  return { program, isLoading, error };
};

export const useFetchLastProgramsStats = (nbDays = 7) => useFetchItemDailyCount('programs', 'created_at', nbDays);

export const useFetchCoachProgRegStats = (coachId, nbDays = 7) => {
  const [collectionName, setCollectionName] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [conditions, setConditions] = useState([]);
  const { programs, isLoading: isLoadingPrograms, error: programsError } = useFetchCoachPrograms(coachId);
  const { data: progRegCountData, error: countError } = useFetchItemDailyCount(
    collectionName,
    'created_at',
    nbDays,
    conditions
  );

  useEffect(() => {
    if (!isLoadingPrograms) {
      if (progRegCountData) {
        setIsLoading(false);
      } else {
        const programsId = programs.length ? programs.map((program) => program.id) : [''];
        setConditions([['program_id', 'in', programsId]]);
        setCollectionName('programs_registration');
      }
    }
    if (programsError || countError) {
      setError(programsError || countError);
    }
  }, [programs, progRegCountData, programsError, countError, isLoadingPrograms]);

  return { data: progRegCountData, isLoading, error };
};

export const useCountRegisteredPrograms = (programId = null) => {
  const [isLoadingRegisteredPrograms, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const collectionName = 'programs_registration';
      const programsRef = collection(db, collectionName);
      const programsQuery = query(programsRef, where('program_id', 'array-contains', programId));
      const snapshot = await getCountFromServer(programId ? programsQuery : programsRef);
      setCount(snapshot.data().count);
    };

    fetchData()
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, [programId]);

  return { count, isLoadingRegisteredPrograms, error };
};

export const useFetchProgramEnrollStats = (programId, nbDays = 7) =>
  useFetchItemDailyCount('programs_registration', 'created_at', nbDays, [
    ['program_id', '==', programId],
    ['status', '==', 'REGISTERED'],
  ]);

export const useFetchProgramUnenrollStats = (programId, nbDays = 7) =>
  useFetchItemDailyCount('programs_registration', 'created_at', nbDays, [
    ['program_id', '==', programId],
    ['status', '==', 'CONCLUDED'],
  ]);

export const useFetchUserPrograms = (userId) => {
  const [userPrograms, setUserPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersRef = collection(db, 'programs_registration');
        const userProgramsQuery = query(usersRef, where('user_id', '==', userId));

        const userProgramsSnapshot = await getDocs(userProgramsQuery);

        const userProgramsData = userProgramsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        setUserPrograms(userProgramsData);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [userId]);
  return { userPrograms, isLoading, error };
};

export const updateUserProgramStatusById = async (programId, newStatus) => {
  const userProgramDocRef = doc(db, 'programs_registration', programId);

  try {
    await updateDoc(userProgramDocRef, {
      status: newStatus,
    });
    return { success: true };
  } catch (error) {
    console.error('Error updating user program status:', error);
    return { success: false, error: error.message };
  }
};

