import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useState, useContext } from 'react';
// @mui
import {
  Card,
  Container,
  LinearProgress,
  Typography,
  Avatar,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  Stack,
  TableRow,
  Button,
  Popover,
  MenuItem,
  IconButton,
  TableHead,
  Paper,
  Chip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import Iconify from '../components/iconify/Iconify';
import { fDateTime } from '../utils/formatTime';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { useFetchItem } from '../services/item_fetch_service';
import { formatImage } from '../utils/formatImage';
import { useFetchProgramSessions } from '../services/sessions_service';
import { UserListHead } from '../sections/@dashboard/user';
import i18n from '../i18n';
import ManagePrograms from './ManagePrograms';
import UserContext from '../contexts/UserContext';
import { useFetchProgram } from '../services/programs_service';

// ----------------------------------------------------------------------
export default function ProgramPage() {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const { data: program, isLoading } = useFetchItem('programs', id);
  return (
    <>
      <Helmet>
        <title>
          {t('Program')} | {program?.name ?? ''}{' '}
        </title>
      </Helmet>
      {user.userRoles.includes('ADMIN') ? <AdminProgramPage /> : <ManagePrograms />}
    </>
  );
}

function AdminProgramPage() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { program, isLoading } = useFetchProgram(id);

  return (
    <>
      {isLoading && <LinearProgress />}
      {!isLoading && (
        <Container>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: 16,
              height: 480,
              overflow: 'hidden',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              backgroundAttachment: 'scroll, scroll',
              backgroundImage: `linear-gradient(rgba(22, 28, 36, 0.64) 0%, rgba(22, 28, 36, 0.64) 100%), url(${formatImage(
                program?.page_image ?? program?.thumbnail ?? program?.wide_image ?? program?.image,
                { h: 480 }
              )})`,
              backgroundOrigin: 'padding-box, padding-box',
              backgroundClip: 'border-box, border-box',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
            }}
          >
            <Typography color={'white'} variant="h4" sx={{ mb: 5, flex: 1 }}>
              {t('Program')} | {program?.name}
            </Typography>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={5}
            >
              {program?.coaches_id.map((coachId) => (
                <CoachSection id={coachId} key={coachId} />
              ))}
            </Stack>
          </div>
        </Container>
      )}

      {!isLoading && (
        <Container>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              sx={{
                borderRadius: '20px',
                marginTop: '5px',
                width: '120px',
                '&:hover': { backgroundColor: '#2196f3' },
              }}
              href={`/dashboard/programs/${id}/manage`}
            >
              {t('Manage')}
            </Button>
          </div>

          <Typography variant="h6" sx={{ mb: 5, mt: 8 }}>
            {program?.description}
          </Typography>

          <Typography variant="h6" sx={{ mb: 5 }}>
            {program?.price} {t('RUB')} | {program?.numberOfParticipants ?? 0} {t('members')} | {t('Rating')}:{' '}
            {program?.rating}
          </Typography>

          <div style={{ width: '100%', height: '1px', backgroundColor: 'lightgray' }} />

          <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>
            {t('Members')}
          </Typography>

          {(program?.members ?? []).map((userId) => (
            <UserSection key={userId} id={userId} />
          ))}

          {(program?.members ?? []).length === 0 && (
            <Typography variant="body2" sx={{ mb: 5 }}>
              {t('No Members')}
            </Typography>
          )}

          <div style={{ width: '100%', height: '1px', backgroundColor: 'lightgray', marginTop: '2.5rem' }} />

          <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>
            {t('Sessions')}
          </Typography>

          {<SessionSection programId={id} />}

          <div style={{ width: '100%', height: '1px', backgroundColor: 'lightgray', marginTop: '2.5rem' }} />

          <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>
            {t('Packages')}
          </Typography>

          <PackageSection program={program} />
        </Container>
      )}
    </>
  );
}

function CoachSection({ id }: { id: string }) {
  const { data: coach, isLoading } = useFetchItem('coaches', id);

  if (isLoading || !coach) {
    return <div />;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        alt={coach.name}
        src={formatImage(coach.image, { w: 128, h: 128 })}
        sx={{ width: 64, height: 64, marginRight: '16px' }}
      />

      <div>
        <Typography color={'white'} variant="body1" sx={{ marginBottom: '4px' }}>
          {coach.name}
        </Typography>
        <Typography color={'white'} variant="body2">
          {coach.city}, {coach.country}
        </Typography>
      </div>
    </div>
  );
}

function UserSection({ id, ...props }: { id: string }) {
  const { data: coach, isLoading } = useFetchItem('users', id);

  if (isLoading || !coach) {
    return <div />;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }} {...props}>
      <Avatar
        alt={coach.name}
        src={formatImage(coach.image, { w: 128, h: 128 })}
        sx={{ width: 64, height: 64, marginRight: '16px' }}
      />

      <div>
        <Typography variant="body1" sx={{ marginBottom: '4px' }}>
          {coach.name}
        </Typography>
        <Typography variant="body2">
          {coach.city}, {coach.country}
        </Typography>
      </div>
    </div>
  );
}

function PackageSection({ program }) {
  const { t } = useTranslation();

  return (
    <>
      {program?.sessions && (
        <Stack direction="row" marginBottom={4} gap={2}>
          {program?.sessions?.map((session) => (
            <Chip size="medium" variant="elevated" label={`${session.name} (${session.price} ${program.currency})`} />
          ))}
        </Stack>
      )}

      {!program?.packages?.length ? (
        <Typography variant="body2" sx={{ mb: 5 }}>
          {t('No Packages')}
        </Typography>
      ) : (
        <Card>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Name')}</TableCell>
                  <TableCell>{t('Options')}</TableCell>
                  <TableCell>{t('Total')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {program?.packages?.map((packageItem) => {
                  const findSessionNameById = (id) => {
                    return program?.sessions?.find((session) => session.id === id)?.name;
                  };

                  return (
                    <TableRow>
                      <TableCell>{packageItem.name}</TableCell>
                      <TableCell>
                        {packageItem.content.map(
                          (item) =>
                            item.count !== 0 && (
                              <div>
                                {item.count} x {findSessionNameById(item.session_type_id)}
                              </div>
                            )
                        )}
                      </TableCell>
                      <TableCell>
                        {packageItem.total} {program.currency}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      )}
    </>
  );
}

function SessionSection({ programId }: { programId: string }) {
  const { sessions, isLoading } = useFetchProgramSessions(programId);
  const { t } = useTranslation();

  const [editingSession, setEditingSession] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const handleOpenMenu = (event, id) => {
    setEditingSession({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setEditingSession(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (isLoading || !sessions) return <div />;

  if (sessions && sessions.length === 0)
    return (
      <Typography variant="body2" sx={{ mb: 5 }}>
        {t('No Sessions')}
      </Typography>
    );

  return (
    <Card>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onRequestSort={handleRequestSort} />
            <TableBody>
              {sessions.map((row) => {
                const { id, name, image, sessionType, scheduledFor } = row;

                return (
                  <TableRow hover key={id} tabIndex={-1} role="checkbox">
                    <TableCell component="th" scope="row">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar alt={name} src={image} />
                        <Typography variant="subtitle2" noWrap>
                          {name}
                        </Typography>
                      </Stack>
                    </TableCell>

                    <TableCell align="left">{t(sessionType)}</TableCell>

                    <TableCell align="left">{fDateTime(scheduledFor.toMillis())}</TableCell>
                    <TableCell align="right">
                      <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                        <Iconify icon={'eva:more-vertical-fill'} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {sessions.length === 0 && (
                <TableRow style={{ height: 53 }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Popover
          open={Boolean(editingSession)}
          anchorEl={editingSession?.anchor}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem sx={{ color: 'info.dark' }}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            {t('Edit')}
          </MenuItem>
          <MenuItem sx={{ color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            {t('Delete')}
          </MenuItem>
        </Popover>
      </Scrollbar>
    </Card>
  );
}

const TABLE_HEAD = [
  { id: 'name', label: i18n.t('Name'), alignRight: false },
  { id: 'sessionType', label: i18n.t('Module'), alignRight: false },
  { id: 'scheduledFor', label: i18n.t('Scheduled For'), alignRight: false },
  {},
];
