import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishTranslation from './translations/en.json';
import russianTranslation from './translations/ru.json';
import { enCountryCityState, ruCountryCityState } from './translations/country-state-city';
import { getSelectedLanguage } from "./services/language_service";


const resources = {
  en: {
    "translation": Object.assign(englishTranslation, enCountryCityState)
  },
  ru: {
    "translation": Object.assign(russianTranslation, ruCountryCityState)
  }
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getSelectedLanguage(), // if you're using a language detector, do not define the lng option
    fallbackLng: "ru",
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18n;