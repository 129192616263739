import { Alert, Box, Button, ButtonBase, Card, CardActions, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PropTypes from 'prop-types';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { formDataToObject } from "../../utils/formDataConverter";

const DURATION_UNIT_OPTIONS = ['Minutes', 'Hours', 'Days', 'Weeks', 'Months'];

PackageSection.propTypes = {
  packagesState: PropTypes.array.isRequired,
  currency: PropTypes.string.isRequired,
  sessions: PropTypes.array
};

export default function PackageSection({ packagesState, currency, sessions = [] }) {
  const { t } = useTranslation();
  const [isLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formError, setFormError] = useState(null);
  const [packages, setPackages] = packagesState;

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isDelDialogOpen, setDelDialogOpen] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [durationUnit, setDurationUnit] = useState(DURATION_UNIT_OPTIONS[1]);
  const isEditMode = selectedPackage !== null;

  useEffect(() => {
    const updatedPackages = packages.map(pack => {
      const content = pack.content.filter(el => findSessionById(el.session_type_id));
      const total = content.reduce((acc, el) => (
        acc + findSessionById(el.session_type_id).price * el.count
      ), 0);
      return { ...pack, content, total };
    });
    setPackages(updatedPackages);
  }, [sessions]);

  const findSessionById = (id) => sessions.find(session => session.id === id);

  const handleDurationUnitChange = (event) => {
    setDurationUnit(event.target.value);
  };

  const handleClickOpen = () => {
    if (!sessions.length) {
      setError(t('Create at least one module before'));
      return;
    }
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setError(null);
    setFormError(null);
    setSelectedPackage(null);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const formData = new FormData(ev.currentTarget);
    const data = formDataToObject(formData);
    const content = [];
    let { duration } = data;
    let _durationUnit = durationUnit;

    if (!data.name) {
      setFormError(t("Please, enter the package name"));
      return;
    }
    if (Object.values(data).every(el => Number.isNaN(Number(el)) ? true : Number(el) === 0)) {
      setFormError(t("Please add at least one module to this package"));
      return;
    }

    const sessionFieldsData = Object.keys(data)
      .filter(name => name !== 'name' && name !== 'duration');

    const total = sessionFieldsData.reduce((acc, id) => {
      const count = Number(data[id]);
      const { price } = findSessionById(id);
      content.push({ session_type_id: id, count });
      return acc + count * price;
    }, 0);

    if (duration.trim() === '') {
      duration = sessionFieldsData.reduce((acc, id) => {
        const count = Number(data[id]);
        const { duration, duration_unit: durationUnit } = findSessionById(id);
        let newDuration = duration;

        if (durationUnit === 'Hours') newDuration *= 60;
        else if (durationUnit === 'Days') newDuration *= 60 * 24;
        else if (durationUnit === 'Weeks') newDuration *= 60 * 24 * 7;
        else if (durationUnit === 'Months') newDuration *= 60 * 24 * 30;

        return acc + count * newDuration;
      }, 0);
      _durationUnit = 'Minutes';
    } else {
      duration = Number(duration);
    }

    const newPackage = {
      name: data.name,
      content,
      total,
      duration,
      duration_unit: _durationUnit,
      id: isEditMode ? selectedPackage.id : uuidv4()
    };

    if (isEditMode) {
      const prev = packages;
      prev[prev.findIndex(el => el.id === selectedPackage.id)] = newPackage;
      setSelectedPackage(null);
      setDurationUnit(DURATION_UNIT_OPTIONS[1]);
    } else {
      setPackages(prev => [...prev, newPackage]);
    }

    handleClose();
  };

  const handleClickEdit = (pack) => {
    setSelectedPackage(pack);
    setDurationUnit(pack.duration_unit);
    setDialogOpen(true);
  };

  const handleCloseDelDialog = () => {
    setToDeleteId(null);
    setDelDialogOpen(false);
  };

  const handleDelete = (pack) => {
    setToDeleteId(pack.id);
    setDelDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (toDeleteId) {
      setPackages(prev => prev.filter(el => el.id !== toDeleteId));
      handleCloseDelDialog();
    }
  };

  return (
    <Stack spacing={3}>
      <Typography variant="h5" gutterBottom>
        {t('Packages')}
      </Typography>

      {error && <Alert severity="error" onClose={() => setError(null)}>{error}</Alert>}

      <Grid container spacing={2}>
        {packages.length !== 0 && (
          packages.map(pack => {
            let unit = pack.duration_unit.toLowerCase();
            unit = unit.substr(0, unit.length - 1);
            const duration = t(`ct.${unit}`, { count: pack.duration });

            return (
              <Grid xs={12} sm={12} md={6} lg={4} key={pack.id}>
                <Card sx={{ height: '100%' }}>
                  <Stack padding='1.5rem' height='100%'>
                    <Typography variant="h5" component="div">
                      {pack.name}
                    </Typography>
                    <Typography gutterBottom variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
                      {duration}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 4 }}>
                      <div>
                        <ul style={{ marginLeft: '1rem' }}>
                          {pack.content.map(el => el.count !== 0 && <li key={el.session_type_id}> {`${el.count} x ${findSessionById(el.session_type_id)?.name}`} </li>)}
                        </ul>

                      </div>
                    </Typography>
                    <Box sx={{ background: "#0001", borderRadius: 2, margin: 'auto 0 0', padding: 1, textAlign: 'center', fontWeight: 'medium' }}>
                      {`${pack.total} ${currency}`}
                    </Box>
                    <CardActions sx={{ justifyContent: 'center' }}>
                      <Button size="small" onClick={() => handleClickEdit(pack)}>{t('Edit')}</Button>
                      <Button size="small" onClick={() => handleDelete(pack)} color="error">{t('Delete')}</Button>
                    </CardActions>
                  </Stack>
                </Card>
              </Grid>
            );
          })
        )}
        <Grid xs={12} sm={12} md={6} lg={4}>
          <ButtonBase display='flex' justifyContent='center' alignItems='center' sx={{
            height: '100%',
            width: '100%',
            color: 'rgb(189, 189, 189)',
            cursor: 'pointer',
            padding: 4,
            border: '2px dashed rgb(238, 238, 238)',
            borderRadius: 1
          }}
            onClick={handleClickOpen}
          >
            {t('Add')}
          </ButtonBase>
        </Grid>
      </Grid>

      {/* Edition and creation form dialog */}
      <Dialog open={isDialogOpen} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>{t(isEditMode ? "Edit a package" : "Create a package")}</DialogTitle>

          <DialogContent>
            {formError && <Alert severity="error" onClose={() => setFormError(null)}>{formError}</Alert>}

            <InputLabel sx={{ marginTop: 1 }} id="package-name-label">{t('name')}</InputLabel>
            <TextField
              sx={{ marginY: '1rem' }}
              size="small"
              id="name"
              name="name"
              fullWidth
              variant="outlined"
              defaultValue={isEditMode ? selectedPackage.name : ''}
            />

            <Stack direction="row" gap={2}>
              <TextField
                name="duration"
                size="small"
                labelId="duration-label"
                label={`${t('duration')} (${t('Optional')})`}
                type={'number'}
                defaultValue={isEditMode ? selectedPackage.duration : ''}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />
              <Select labelId="duration-type-label" value={durationUnit} onChange={handleDurationUnitChange} size="small">
                <MenuItem key={'Auto'} value={'Auto'}>
                  {t('Auto')}
                </MenuItem>
                {DURATION_UNIT_OPTIONS.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t(option)}
                  </MenuItem>
                ))}
              </Select>
            </Stack>

            {sessions.length !== 0 &&
              <>
                <InputLabel sx={{ marginTop: 4 }} id="nb-session-label">{t('Number of sessions types for the package')}</InputLabel>
                <Grid marginTop='.5rem' container spacing={2}>
                  {sessions.map(({ id, name }) => (
                    <Grid xs={4} key={id}>
                      <TextField
                        size="small"
                        name={`${id}`}
                        label={name}
                        placeholder="0"
                        type="number"
                        defaultValue={selectedPackage?.content.find(el => el.session_type_id === id)?.count || 0}
                        labelId={`price-label-${id}`}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                    </Grid>
                  ))}
                </Grid>
              </>}
          </DialogContent>
          <DialogActions sx={{ gap: 2, margin: 1 }}>
            <LoadingButton onClick={handleClose} variant="outlined" disabled={isLoading}>
              {t('Cancel')}
            </LoadingButton>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
              disabled={isLoading}
            >
              {t(isEditMode ? 'update' : 'create')}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog open={isDelDialogOpen} onClose={handleCloseDelDialog}>
        <DialogTitle>{t('Confirm Delete')}</DialogTitle>
        <DialogContent>{`${t('Are you sure you want to delete this')} ${t('package')} ?`}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelDialog}>{t('Cancel')}</Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>

    </Stack>
  );
}
