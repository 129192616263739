import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

// @mui
import { styled, alpha, useTheme } from '@mui/material/styles';
import { Box, Link, Typography, Avatar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MuiDrawer from '@mui/material/Drawer';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// mock
import { useTranslation } from 'react-i18next';

// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import UserContext from '../../../contexts/UserContext';
import { formatImage } from '../../../utils/formatImage';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const drawerWidth = 240;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
  '&:hover': {
    backgroundColor: 'lightblue',
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

// ----------------------------------------------------------------------

// Nav.propTypes = {
//   openNav: PropTypes.bool,
//   onCloseNav: PropTypes.func,
// };

export default function Nav() {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  // const { pathname } = useLocation();

  const theme = useTheme();
  const [openOnHover, setOpenOnHover] = React.useState(false);
  const [isToggledOpen, setIsToggledOpen] = useState(() => {
    const savedState = localStorage.getItem('drawerOpen');
    return savedState !== null ? JSON.parse(savedState) : false;
  });
  const handleMouseEnter = () => {
    if (!isToggledOpen) {
      setOpenOnHover(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isToggledOpen) {
      setOpenOnHover(false);
    }
  };

  const handleToggle = () => {
    setIsToggledOpen((prevState) => !prevState);
  };

  useEffect(() => {
    localStorage.setItem('drawerOpen', JSON.stringify(isToggledOpen));
  }, [isToggledOpen]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'flex', alignItems: 'center' }}>
        <Logo />
      </Box>
      {(openOnHover || isToggledOpen) && (
        <Box sx={{ mb: 5, mx: 2.5 }}>
          <Link underline="none" href={"/dashboard/profile"}>
            <StyledAccount>
              <Avatar src={formatImage(user?.image, {w: 100, h: 100})} alt="photoURL" />

              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                  {user?.name?.split(" ").map(na => na[0]?.toUpperCase()).join(" . ")}
                </Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {user?.userRoles.map(t).join(' - ')}
                </Typography>
              </Box>
            </StyledAccount>
          </Link>
        </Box>
      )}
      <NavSection isCollapsed={!(openOnHover || isToggledOpen)} data={navConfig} />
    </Scrollbar>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        open={openOnHover || isToggledOpen}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        variant="permanent"
        PaperProps={{
          sx: {
            width: NAV_WIDTH,
            bgcolor: 'background.default',
            borderRightStyle: 'dashed',
          },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={handleToggle}>
            {openOnHover || isToggledOpen ? (
              theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )
            ) : theme.direction === 'rtl' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>

        {renderContent}
      </Drawer>
    </Box>
  );
}
