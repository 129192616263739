import {useEffect, useState} from 'react';
import {
    collection,
    deleteDoc,
    doc,
    getCountFromServer,
    getDocs,
    query,
    setDoc,
    where,
} from 'firebase/firestore';
import {v4 as uuidv4} from 'uuid';
import {db} from '../firebaseConfig';
import {useFetchItemDailyCount} from './item_daily_count_fetch_service';
import toProperCase from '../utils/toProperCase';

export const useFetchAllSessions = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [sessions, setSessions] = useState([]);
    const [error, setError] = useState(null);

    const reloadSessions = () => {
        setIsLoading(true);
        const fetchData = async () => {
            const collectionName = 'sessions';

            const sessionsRef = collection(db, collectionName);
            const querySnapshot = await getDocs(sessionsRef);
            const sessions = querySnapshot.docs.map((doc) => doc.data());
            setSessions(
                sessions.map((session) => ({
                    ...session,
                    name: toProperCase(session.name),
                }))
            );
        };

        fetchData()
            .catch(setError)
            .finally(() => setIsLoading(false));
    };

    useEffect(reloadSessions, []);

    return {sessions, reloadSessions, isLoading, error};
};

export async function deleteSession(sessionId) {
    try {
        return await deleteDoc(doc(db, 'sessions', sessionId));
    } catch (error) {
        console.error('Error deleting user:', error);
        throw error;
    }
}

export const useFetchCoachSessions = (coachId) => {
    const [isLoading, setIsLoading] = useState(true);
    const [sessions, setSessions] = useState([]);
    const [error, setError] = useState(null);

    const reloadSessions = () => {
        if (!coachId) {
            setIsLoading(false);
            return;
        }

        const fetchData = async () => {
            const collectionName = 'sessions';

            const programsRef = collection(db, collectionName);
            const q = query(programsRef, where('coaches_id', 'array-contains', coachId));
            const querySnapshot = await getDocs(q);
            const _sessions = querySnapshot.docs.map((doc) => doc.data());
            setSessions(
                _sessions.map((session) => ({
                    ...session,
                    name: toProperCase(session.name),
                }))
            );
        };

        fetchData()
            .catch(setError)
            .finally(() => setIsLoading(false));
    };

    useEffect(reloadSessions, [coachId]);

    return {sessions, isLoading, reloadSessions, error};
};

export const useFetchProgramSessions = (programId) => {
    const [isLoading, setIsLoading] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!programId) return;

        setIsLoading(true);
        const fetchData = async () => {
            const collectionName = 'sessions';

            const sessionsRef = collection(db, collectionName);
            const q = query(sessionsRef, where('programId', '==', programId));
            const querySnapshot = await getDocs(q);
            const sessions = querySnapshot.docs.map((doc) => doc.data());
            setSessions(sessions);
        };

        fetchData()
            .catch(setError)
            .finally(() => setIsLoading(false));
    }, [programId]);

    return {sessions, isLoading, error};
};

export const useCountSessions = (coachId = null) => {
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            const collectionName = 'sessions';
            const sessionsRef = collection(db, collectionName);
            const sessionQuery = query(sessionsRef, where('coach_id', '==', coachId));
            const snapshot = await getCountFromServer(coachId ? sessionQuery : sessionsRef);
            setCount(snapshot.data().count);
        };

        fetchData()
            .catch(setError)
            .finally(() => setIsLoading(false));
    }, [coachId]);

    return {count, isLoading, error};
};

export async function createCoachChatSession(payload, member, coach) {
    const memberId = member.id;
    const coachId = coach.id;
    const id = memberId + coachId;
    payload.id = id;
    payload.chatId = id;
    payload.members = [memberId, coachId];

    const coachChatRefCollection = doc(collection(db, "chats"), coachId, "chats", memberId)
    const memberChatRefCollection = doc(collection(db, "chats"), memberId, "chats", coachId)

    const coachChatPayload = {coach_id: coachId, name: member.name, members: [memberId, coachId], messages: [], image: member.image};
    const memberChatPayload = {coach_id: coachId, name: coach.name, members: [memberId, coachId], messages: [], image: coach.image};

    setDoc(coachChatRefCollection, coachChatPayload, {merge: true})
    setDoc(memberChatRefCollection, memberChatPayload, {merge: true})
}

export async function createCoachVideoCallSession(payload, members) {
    const id = uuidv4();
    payload.id = id;
    payload.members = [...members];
    return setDoc(doc(db, 'sessions', id), payload);
}

export const useFetchLastSessionsStats = (nbDays = 7) => useFetchItemDailyCount('sessions', 'scheduledFor', nbDays);

export const useFetchCoachLastSessionsStats = (coachId, nbDays = 7) => {
    const conditions = [['coachId', '==', coachId]];
    return useFetchItemDailyCount('sessions', 'scheduledFor', nbDays, conditions);
};
