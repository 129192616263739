import enCitiesTranslation from './en_cities.json';
import enCountriesTranslation from './en_countries.json';
import enStatesTranslation from './en_states.json';
import ruCitiesTranslation from './ru_cities.json';
import ruCountriesTranslation from './ru_countries.json';
import ruStatesTranslation from './ru_states.json';

const enCountryCityState = Object.assign(enCitiesTranslation, enCountriesTranslation, enStatesTranslation);
const ruCountryCityState = Object.assign(ruCitiesTranslation, ruCountriesTranslation, ruStatesTranslation);

export {ruCountryCityState, enCountryCityState};