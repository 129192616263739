import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import ImagePreviewPage from './layouts/image-preview';
//
import Programs from './pages/Programs';
import CoachesPage from './pages/CoachesPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProgramPage from './pages/ProgramPage';
import ManagePrograms from './pages/ManagePrograms';
import DashboardAppPage from './pages/DashboardAppPage';
import AddCoachPage from './pages/AddCoachPage';
import EditCoachPage from './pages/EditCoachPage';
import EditUserPage from './pages/EditUserPage';
import EditProgramPage from './pages/EditProgramPage';
import EditQualification from './pages/EditQualification';
import ProgramsPage from './pages/ProgramsPage';
import MyProgramsPage from './pages/MyProgramsPage';
import AddProgramPage from './pages/AddProgramPage';
import AddSessionPage from './pages/AddSessionPage';
import ChatsPage from './pages/ChatsPage';
import ProfilePage from './pages/ProfilePage';
import UsersPage from './pages/UsersPage';
import CategoriesPage from './pages/CategoriesPage';
import CategoryPage from './pages/CategoryPage';
import UserDetailsPage from './pages/UserDetailsPage';
import CoachDetailsPage from './pages/CoachDetailsPage';
import RestrictedByRole from './components/restrictedByRole';
//----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        {
          path: 'coaches',
          element: <RestrictedByRole unauthorized={['COACH']} />,
          children: [
            { path: '', element: <CoachesPage /> },
            { path: 'create', element: <AddCoachPage /> },
            { path: ':id/edit', element: <EditCoachPage /> },
            { path: ':coachid/qualifications/:id/edit', element: <EditQualification /> },
            { path: ':id', element: <CoachDetailsPage /> },
          ],
        },
        {
          path: 'programs',
          element: <RestrictedByRole unauthorized={[]} />,
          children: [
            { path: '', element: <ProgramsPage /> },
            { path: 'create', element: <AddProgramPage /> },
            { path: ':id/edit', element: <EditProgramPage /> },
            { path: ':id', element: <ProgramPage /> },
            {
              path: ':id/manage',
              element: (
                <RestrictedByRole unauthorized={['COACH']}>
                  <ManagePrograms />
                </RestrictedByRole>
              ),
            },
          ],
        },
        { path: 'users/:id', element: <UserDetailsPage /> },
        { path: 'users/:id/edit', element: <EditUserPage /> },
        { path: 'my-programs', element: <MyProgramsPage /> },
        // {
        //   path: 'sessions',
        //   element: (
        //     <RestrictedByRole unauthorized={['COACH']}>
        //       <SessionsPage />
        //     </RestrictedByRole>
        //   ),
        // },
        { path: 'sessions/create', element: <AddSessionPage /> },
        // { path: 'sessions/:id/edit', element: <AddSessionPage /> },
        // { path: 'my-sessions', element: <MySessionsPage /> },
        { path: 'chats/:id?', element: <ChatsPage /> },
        { path: 'products', element: <ProgramPage /> },
        { path: 'blog', element: <Programs /> },
        { path: 'profile', element: <ProfilePage /> },
        {
          path: 'users',
          element: (
            <RestrictedByRole unauthorized={['COACH']}>
              <UsersPage />
            </RestrictedByRole>
          ),
        },
        {
          path: 'categories',
          element: <RestrictedByRole unauthorized={['COACH']} />,
          children: [
            { path: '', element: <CategoriesPage /> },
            { path: ':id', element: <CategoryPage /> },
          ],
        },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/image-preview/:imageUrl',
      element: <ImagePreviewPage />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
