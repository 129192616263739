import PropTypes from "prop-types";
import { Snackbar, Slide, Alert, IconButton } from "@mui/material";
import ImgIcon from "../img-icon";

Notification.propTypes = {
    open: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    marginTop: PropTypes.number
};

export default function Notification({ open, success, message, onClose, marginTop = 0 }) {
    return <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={onClose}
        TransitionComponent={(props) => <Slide {...props} direction="left" />}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{marginTop: `${marginTop}px`}}
    >
        <Alert
            sx={{ color: "black", background: success ? "#EAF6E8" : "#F4E0E0" }}
            icon={success
                ? <ImgIcon src='/assets/icons/tick_square.svg' alt="success icon" size={20} />
                : <ImgIcon src='/assets/icons/info_circle.svg' alt="failure icon" size={20} />}
            onClose={onClose}
            action={<IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={onClose}
            >
                <ImgIcon src='/assets/icons/x.svg' alt="success icon" size={20} />
            </IconButton>}
        >
            {message}
        </Alert>
    </Snackbar>;
}