import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { Stack, TextField, MenuItem, Typography, Container, LinearProgress, Autocomplete, Chip } from '@mui/material';
import { Alert, LoadingButton } from '@mui/lab';
import { Country, State, City } from 'country-state-city';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Timestamp } from 'firebase/firestore';
import { Helmet } from 'react-helmet-async';
import { editCoachQualificationById, getCoachQualificationById } from '../services/coaches_service';
import FileUpload from '../components/file-upload';

import { uploadFile } from '../utils/fileUploader';
import { useFetchItem } from '../services/item_fetch_service';

// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function EditUserPage() {
  const navigate = useNavigate();
  const { id: qualificationId, coachid: coachId } = useParams();
  const [qualification, setQualification] = useState(null);

  const { t } = useTranslation();

  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQualification = async () => {
      try {
        const result = await getCoachQualificationById(coachId, qualificationId);
        setQualification(result);
      } catch (error) {
        // Handle the error
      }
    };

    fetchQualification();
  }, [coachId, qualificationId]);
  function generatePayloadFromData(data) {
    return {
      programName: data.get('programName'),
      qualification: data.get('qualification'),
      date: data.get('date'),
      image: data.get('image'),
    };
  }
  function navigateToCoachesPage() {
    navigate(`/dashboard/coaches/${coachId}`);
  }
  async function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    setError(null);

    if (!data.get('programName')) {
      setError(t('Upload the Program Name'));
      return;
    }

    if (!data.get('qualification')) {
      setError(t('Enter the qualification'));
      return;
    }

    if (!data.get('date')) {
      setError(t('Enter the Date'));
      return;
    }

    setIsLoading(true);

    const imageUrl = image ? await uploadFile(image) : qualification?.image;
    data.set('image', imageUrl);

    const payload = generatePayloadFromData(data);
    payload.updated_at = Timestamp.now();

    editCoachQualificationById(coachId, qualificationId, payload)
      .then(navigateToCoachesPage)
      .catch((error) => setError(error.message))
      .finally(() => setIsLoading(false));
  }

  return (
    <>
      {isLoading && <LinearProgress />}

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              {`${t('edit qualification')} | ${qualification?.programName}`}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Stack spacing={3} mb={3}>
                {qualification?.programName && (
                  <>
                    <TextField name="programName" label={t('Program Name')} defaultValue={qualification?.programName} />
                    <TextField
                      name="qualification"
                      label={t('Qualification')}
                      defaultValue={qualification?.qualification}
                    />
                    <TextField name="date" label={t('Date')} defaultValue={qualification?.date} />
                  </>
                )}
                <FileUpload maxSize={10} onChange={setImage} />
              </Stack>

              {error && <Alert severity="error">{error}</Alert>}
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                <LoadingButton
                  fullWidth
                  size="large"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  variant="outlined"
                  disabled={isLoading}
                >
                  {t('Cancel')}
                </LoadingButton>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  {t('Update')}
                </LoadingButton>
              </Stack>
            </form>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
