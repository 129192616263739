import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { Stack, TextField, MenuItem, Typography, Container, LinearProgress, Autocomplete, Chip } from '@mui/material';
import { Alert, LoadingButton } from '@mui/lab';
import { Country, State, City } from 'country-state-city';

import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Timestamp } from 'firebase/firestore';
import { createCoach } from '../services/coaches_service';

import { uploadFile } from '../utils/fileUploader';

import FileUpload from '../components/file-upload';
import Logo from '../components/logo';
import { useFetchItem } from '../services/item_fetch_service';

// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function EditCoachPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: coach, isLoading: isInitialLoading } = useFetchItem('coaches', id);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');

  const { t } = useTranslation();

  const [image, setImage] = useState();

  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setTags(coach?.tags || []);
  }, [coach]);

  function navigateToCoachesPage() {
    navigate('/dashboard/coaches');
  }

  function generatePayloadFromData(data) {
    return {
      email: data.get('email'),
      password: data.get('password'),
      name: data.get('name'),
      country: selectedCountry,
      state: selectedState,
      city: selectedCity,
      profession: data.get('profession'),
    };
  }

  function attachNonFormFields(data) {
    data.created_at = Timestamp.now();
    data.updated_at = Timestamp.now();
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const payload = generatePayloadFromData(data);
    attachNonFormFields(payload);
    setError(null);

    if (!payload.email) {
      setError(t("Upload the coach's email"));
      return;
    }

    if (!payload.name) {
      setError(t("Enter the coach's name"));
      return;
    }

    if (!payload.country) {
      setError(t("Enter the coach's country"));
      return;
    }
    if (!payload.state) {
      setError(t("Enter the coach's state"));
      return;
    }

    if (!payload.city) {
      setError(t("Enter the coach's city"));
      return;
    }
    if (!payload.password) {
      setError(t("Upload the coach's password"));
      return;
    }
    if (!image) {
      setError(t("Upload the coach's image"));
      return;
    }
    if (!payload.profession) {
      setError(t("Enter the coach's profession"));
      return;
    }

    setIsLoading(true);

    const imageUrl = await uploadFile(image);
    data.set('image', imageUrl);

    createCoach(payload)
      .then(navigateToCoachesPage)
      .catch((error) => setError(error.message))
      .finally(() => setIsLoading(false));
  }
  const onChangeTags = (event, newTags) => {
    setTags(newTags);
  };

  useEffect(() => {
    const countryData = Country.getAllCountries();
    setCountries(countryData);
  }, []);
  useEffect(() => {
    if (selectedCountry) {
      const stateData = State.getStatesOfCountry(selectedCountry);
      setStates(stateData);
    } else {
      setStates([]);
    }
    setSelectedState('');
    setSelectedCity('');
    setCities([]);
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      const cityData = City.getCitiesOfState(selectedCountry, selectedState);

      setCities(cityData);
    } else {
      setCities([]);
    }
    setSelectedCity('');
  }, [selectedState]);

  return isInitialLoading ? (
    <LinearProgress />
  ) : (
    <>
      {isLoading && <LinearProgress />}
      <Helmet>
        <title>{`${t('edit_coach')} | ${coach?.name}`}</title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              {`${t('edit_coach')} | ${coach?.name}`}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Stack spacing={3} mb={3}>
                <TextField name="name" label={t('Name')} defaultValue={coach?.name} />
                <TextField name="email" label={t('Email address')} defaultValue={coach?.email} />
                <TextField name="phone" label={t('Phone')} defaultValue={coach?.phone} />
                <TextField name="profession" label={t('Profession')} defaultValue={coach?.profession} />
                <TextField
                  select
                  label={t('Country')}
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    {t('Select Country')}
                  </MenuItem>
                  {countries.map((country) => (
                    <MenuItem key={country.isoCode} value={country.isoCode}>
                      {t(country.name)}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  label={t('State')}
                  value={selectedState}
                  onChange={(e) => setSelectedState(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    {t('Select State')}
                  </MenuItem>
                  {states.map((state) => (
                    <MenuItem key={state.isoCode} value={state.isoCode}>
                      {t(state.name)}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  label={t('City')}
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    {t('Select City')}
                  </MenuItem>
                  {(cities || []).length ? (
                    cities.map((city) => (
                      <MenuItem key={city.name} value={city.name}>
                        {t(city.name)}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled>
                      No cities available
                    </MenuItem>
                  )}
                </TextField>
                <FileUpload maxSize={10} edit onChange={setImage} />

                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  value={tags}
                  onChange={onChangeTags}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip key={option} variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => <TextField {...params} placeholder={t('tags')} />}
                />
              </Stack>

              {error && <Alert severity="error">{error}</Alert>}
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                <LoadingButton
                  fullWidth
                  size="large"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  variant="outlined"
                  disabled={isLoading}
                >
                  {t('Cancel')}
                </LoadingButton>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  {t('Update')}
                </LoadingButton>
              </Stack>
            </form>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
