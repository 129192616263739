import { useEffect, useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import {
  collection,
  getCountFromServer,
  getDocs,
  getDoc,
  setDoc,
  deleteDoc,
  query,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { userCreationAuth, db } from '../firebaseConfig';
import { useFetchItemDailyCount } from './item_daily_count_fetch_service';
import toProperCase from '../utils/toProperCase';

export const useFetchCoaches = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [coaches, setCoaches] = useState([]);
  const [error, setError] = useState(null);

  const reloadCoaches = () => {
    setIsLoading(true);
    const fetchData = async () => {
      const collectionName = 'coaches';

      const coachesRef = collection(db, collectionName);
      const querySnapshot = await getDocs(coachesRef);
      const coaches = querySnapshot.docs.map((doc) => doc.data());
      setCoaches(
        coaches.map((coach) => ({
          ...coach,
          name: toProperCase(coach.name),
        }))
      );
    };

    fetchData()
      .catch(setError)
      .finally(() => setIsLoading(false));
  };

  useEffect(reloadCoaches, []);

  return { coaches, reloadCoaches, isLoading, error };
};

export const getCoachQualifications = async (coachId) => {
  const qualificationsCollectionRef = collection(db, `coaches/${coachId}/qualifications`);
  const qualificationsQuery = query(qualificationsCollectionRef);
  const querySnapshot = await getDocs(qualificationsQuery);

  const qualificationsData = [];
  querySnapshot.forEach((doc) => {
    qualificationsData.push({ id: doc.id, ...doc.data() });
  });

  return qualificationsData;
};

export const getCoachQualificationById = async (coachId, qualificationId) => {
  const qualificationDocRef = doc(db, `coaches/${coachId}/qualifications`, qualificationId);
  const docSnapshot = await getDoc(qualificationDocRef);

  return { id: docSnapshot.id, ...docSnapshot.data() };
};

export const editCoachQualificationById = async (coachId, qualificationId, updatedQualificationData) => {
  const qualificationDocRef = doc(db, `coaches/${coachId}/qualifications`, qualificationId);

  try {
    await updateDoc(qualificationDocRef, updatedQualificationData);
    return { success: true };
  } catch (error) {
    console.error('Error updating qualification:', error);
    return { success: false, error: error.message };
  }
};

export const useCountCoaches = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const collectionName = 'coaches';
      const coachesRef = collection(db, collectionName);
      const snapshot = await getCountFromServer(coachesRef);
      setCount(snapshot.data().count);
    };

    fetchData()
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, []);

  return { count, isLoading, error };
};

export async function createCoach(payload) {
  const user = await createUserWithEmailAndPassword(userCreationAuth, payload.email?.trim(), payload.password);

  const coachId = user.user.uid;
  payload.id = coachId;
  payload.tags = (payload.tags ?? '').split(',');
  if (payload.password){
    delete payload.password;
  }
  return setDoc(doc(db, 'coaches', coachId), payload);
}

export async function deleteCoach(coachId) {
  const qualifications = await getCoachQualifications(coachId);
  await Promise.all(
    qualifications.map((qualification) => deleteDoc(doc(db, 'coaches', coachId, 'qualifications', qualification.id)))
  );
  return deleteDoc(doc(db, 'coaches', coachId));
}

export async function deleteQualification(coachId, qualificationId) {
  try {
    const qualificationDocRef = doc(db, 'coaches', coachId, 'qualifications', qualificationId);

    await deleteDoc(qualificationDocRef);

    return { success: true, message: 'Qualification deleted successfully' };
  } catch (error) {
    console.error('Error deleting qualification:', error);
    throw error;
  }
}

export async function updateCoach(coachId, payload) {
  return updateDoc(doc(db, 'coaches', coachId), payload);
}

export const fetchCoaches = async () => {
  try {
    const response = await fetch('/api/coaches');
    if (response.ok) {
      const coaches = await response.json();
      return coaches;
    }
    throw new Error(`Failed to fetch coaches: ${response.statusText}`);
  } catch (error) {
    console.error('Error fetching coaches:', error);
    throw error;
  }
};

export const useFetchLastCoachesStats = (nbDays = 7) => useFetchItemDailyCount('coaches', 'created_at', nbDays);

export async function addQualification(coachId, qn) {
  const qualificationId = uuidv4();

  const qualificationData = {
    id: qualificationId,
    programName: qn.programName,
    qualification: qn.qualification,
    date: qn.date,
    images: qn.images,
  };

  try {
    const coachRef = doc(db, 'coaches', coachId);
    const qualificationsCollectionRef = collection(coachRef, 'qualifications');
    await setDoc(doc(qualificationsCollectionRef, qualificationId), qualificationData);
    // console.log('Qualification added successfully!');
  } catch (error) {
    console.error('Error adding qualification:', error);
  }
}
