import { collection, doc, onSnapshot, orderBy, query, writeBatch } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';

export const useFetchUserNotifications = (userId) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);

  const generateLink = (infos) => {
    if (infos.type === 'new_message') return `chats/${infos.doc_id}`;
    if (infos.type === 'new_session') return 'sessions';
    return '';
  };

  useEffect(() => {
    if (!userId) return {};

    setIsLoading(true);

    const notifCollection = collection(db, 'notifications', userId, 'values');

    const unsubscribe = onSnapshot(
      query(notifCollection, orderBy('created_at')),
      (snapshot) => {
        let _notifications = [];

        snapshot.forEach((doc) => {
          _notifications.push({ id: doc.id, ...doc.data() });
        });

        _notifications = _notifications.map((notif) => ({
          ...notif,
          created_at: notif.created_at?.toDate(),
          link: generateLink(notif.type_info),
        }));
        setNotifications(_notifications);
        setIsLoading(false);
      },
      (err) => {
        setError(err);
        setIsLoading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [userId]);

  return { notifications, isLoading, error };
};

export const markOneNotificationsAsRead = async (userId, notifId) => {
  const batch = writeBatch(db);

  const ref = doc(db, 'notifications', userId, 'values', notifId);
  batch.update(ref, { is_read: true });

  await batch.commit();
};

export const markAllNotificationsAsRead = async (userId, notifications) => {
  const batch = writeBatch(db);

  notifications.forEach((notif) => {
    const ref = doc(db, 'notifications', userId, 'values', notif.id);
    batch.update(ref, { is_read: true });
  });

  await batch.commit();
};
