import React, { createContext, useState, useEffect } from 'react';
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { LinearProgress } from "@mui/material";
import { onAuthStateChanged } from "firebase/auth";

import { useLocation, useNavigate } from 'react-router-dom';
import { auth, db } from "../firebaseConfig";

const UserContext = createContext({});


export const UserProvider = ({ children }: { children: any }) => {
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    async function updateUserFromFirestore() {
        const adminRef = doc(db, 'admins', userData.id);
        const adminDoc = await getDoc(adminRef);
        if (adminDoc.exists()) {
            setUserData({ ...userData, ...adminDoc.data() });
        }
    }

    useEffect(() => {
        if (isLoading) return;
        if (location.pathname === '/login' && userData) {
            navigate('/dashboard')
        }
        if (location.pathname !== '/login' && !userData) {
            navigate('/login');
        }
    }, [userData, navigate, location, isLoading]);

    useEffect(() => onAuthStateChanged(auth, async (user) => {
        if (!user) {
            setIsLoading(false);
            setUserData(null);
        }

        try {
            const userRoles = [];
            const adminRef = doc(db, 'admins', user.uid);
            const coachRef = doc(db, 'coaches', user.uid);

            const [adminDoc, coachDoc] = await Promise.all([getDoc(adminRef), getDoc(coachRef)]);

            if (adminDoc.exists()) {
                userRoles.push('ADMIN');
            }

            if (coachDoc.exists()) {
                userRoles.push('COACH');
                setUserData({ ...coachDoc.data(), userRoles });
            }

            if (userRoles.length === 0) {
                setUserData(null);
            } else {
                setUserData((prevUserData) => {
                    if (prevUserData && JSON.stringify(prevUserData.userRoles) === JSON.stringify(userRoles)) {
                        return prevUserData;
                    }
                    return { ...prevUserData, userRoles };
                });
            }


            const unsubscribeAdmin = onSnapshot(adminRef, (adminSnapshot) => {
                if (adminSnapshot.exists()) {
                    setUserData((prevUserData) => {
                        const userRoles = prevUserData?.userRoles || [];
                        if (!userRoles.includes('ADMIN')) {
                            userRoles.push('ADMIN');
                        }
                        return { ...prevUserData, userRoles };
                    });
                }
            });

            const unsubscribeCoach = onSnapshot(coachRef, (coachSnapshot) => {
                if (coachSnapshot.exists()) {
                    setUserData((prevUserData) => {
                        const roles = prevUserData?.userRoles || [];
                        const includeCoach = roles.includes('COACH');
                        return ({
                            ...prevUserData,
                            ...coachSnapshot.data(),
                            userRoles: includeCoach ? roles : [...roles, 'COACH']
                        });
                    });
                }
            });

            return () => {
                unsubscribeAdmin();
                unsubscribeCoach();
            };
        } catch (error) {
            console.error('Error fetching user data:', error);
            auth.signOut();
            setUserData(null);
        } finally {
            setIsLoading(false);
        }

        return () => { };
    }), [navigate]);



    return isLoading ?
        <LinearProgress /> :
        <UserContext.Provider value={{ user: userData, isLoading, updateUserFromFirestore }}>
            {children}
        </UserContext.Provider>
};

export default UserContext;

