import PropTypes from 'prop-types';
import { Alert, Autocomplete, Checkbox, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchCategories, useFetchSubCategories } from "../../services/categories_service";
import { useFetchCoaches } from "../../services/coaches_service";
import FileUpload from '../file-upload';

const StyledForm = styled('form')(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    width: '100%'
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto'
  },
}));

const CURRENCY_OPTIONS = ['Rub', 'Dollar', 'Euro', 'BEL Rub'];
const TYPE_OPTIONS = ['Offline', 'Online', 'Hybrid'];

ProgramSection.propTypes = {
  payloadState: PropTypes.array.isRequired,
  error: PropTypes.string
};

export default function ProgramSection({ payloadState, error, isUpdate }) {
  const { t } = useTranslation();

  const [payload, setPayload] = payloadState;

  const { categories } = useFetchCategories();
  const { subCategories } = useFetchSubCategories(payload.category_id);
  const { coaches } = useFetchCoaches();

  const setThumbnail = (value) => {
    setPayload(prev => ({ ...prev, thumbnail: value }));
  };

  const setPageImage = (value) => {
    setPayload(prev => ({ ...prev, page_image: value }));
  };

  const onChangeCategory = (event) => {
    setPayload(prev => ({
      ...prev,
      category_id: event.target.value,
      sub_category_id: ''
    }));
  };

  const onChangeTags = (event, newTags) => {
    setPayload(prev => ({ ...prev, tags: newTags }));
  };

  const handleChangeField = ({ target }) => {
    setPayload(prev => ({ ...prev, [target.name]: target.value }));
  };

  const currentCoach = coaches.find(coach => coach.id === payload.coaches_id[0]);
  const otherCoaches = coaches.filter(coach => coach.id !== payload.coaches_id[0]);

  return (
    <StyledForm>
      <Stack spacing={3} maxWidth={300} minWidth='100%'>
        <Typography variant="h4" sx={{ paddingBottom: '1rem' }} gutterBottom>
          {t(isUpdate ? 'update_a_program' : 'create_a_new_program')}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {t('Program Details')}
        </Typography>

        {error && <Alert severity="error">{error}</Alert>}

        <TextField variant="outlined" placeholder="Piano"
          label={t('name')}
          name="name"
          value={payload.name}
          onChange={handleChangeField} />

        <FormControl fullWidth>
          <InputLabel id="type-label">{t('Type')}</InputLabel>
          <Select
            labelId="type"
            label={t('Type')}
            name="type"
            value={payload.type}
            onChange={handleChangeField}
          >
            {TYPE_OPTIONS.map((type) => (
              <MenuItem key={type} value={type}>
                {t(type)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="category-field-label">{t('Category')}</InputLabel>
          <Select
            labelId="category-field"
            label={t('Category')}
            name="category_id"
            value={payload.category_id || ''}
            onChange={onChangeCategory}
          >
            {!categories?.length ?
              <MenuItem key='none' value="" disabled>
                <em>{t('Empty')}</em>
              </MenuItem>
              : categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="sub-category-field-label">{t('Sub-category')}</InputLabel>
          <Select
            labelId="sub-category-field"
            label={t('Sub-Category')}
            name="sub_category_id"
            value={payload.sub_category_id || ''}
            onChange={handleChangeField}
          >
            {!subCategories?.length ?
              <MenuItem key='none' value="" disabled>
                <em>{t('Empty')}</em>
              </MenuItem>
              : subCategories.map((subCategory) => (
                <MenuItem key={subCategory.id} value={subCategory.id}>
                  {subCategory.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <TextField
          label={t('group')}
          variant="outlined"
          name="group"
          value={payload.group}
          onChange={handleChangeField} />

        <FormControl fullWidth>
          <InputLabel id="coaches-field-label">{t('coaches')}</InputLabel>
          <Select
            multiple
            labelId="coaches-field"
            label={t('coaches')}
            name="coaches_id"
            value={payload.coaches_id}
            onChange={handleChangeField}
            renderValue={(selected) => selected.map(sCoachId => coaches.length ? coaches.find(coach => coach.id === sCoachId).name : ""
            ).join(', ')}
          >
            { 
              currentCoach &&
                <MenuItem value={currentCoach.id} disabled>
                  <Checkbox checked />
                  {currentCoach.name}
                </MenuItem>
            }
            {otherCoaches.map((coach) => (
              <MenuItem key={coach.id} value={coach.id}>
                <Checkbox checked={payload.coaches_id.indexOf(coach.id) > -1} />
                {coach.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="currency-field-label">{t('Currency')}</InputLabel>
          <Select labelId="price-label" label={t('Currency')} name="currency" value={payload.currency} onChange={handleChangeField}>
            {CURRENCY_OPTIONS.map((currencyOption) => (
              <MenuItem key={currencyOption} value={currencyOption}>
                {currencyOption}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="language-field-label">{t('language')}</InputLabel>
          <Select
            labelId="language-field"
            label={t('language')}
            id="language"
            name="language"
            value={payload.language}
            onChange={handleChangeField}
          >
            <MenuItem value="English">{t('English')}</MenuItem>
            <MenuItem value="Russian">{t('Russian')}</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label={t('description')}
          variant="outlined"
          multiline
          rows={4}
          name="description"
          value={payload.description}
          onChange={handleChangeField} />

        <Autocomplete
          multiple
          id="tags-filled"
          options={[]}
          value={payload.tags}
          onChange={onChangeTags}
          freeSolo
          renderTags={(value, getTagProps) => value.map((option, index) => (
            <Chip key={option} variant="outlined" label={option} {...getTagProps({ index })} />
          ))}
          renderInput={(params) => <TextField {...params} placeholder={t('tags')} />} />

        <Stack paddingTop={3} gap={1}>
          <InputLabel id="image-label">{t('image')}</InputLabel>
          <FileUpload defaultFiles={payload.thumbnail} onChange={setThumbnail} />
        </Stack>
        <Stack gap={1}>

          <InputLabel id="wide-image-label">{t('page_image')}</InputLabel>
          <FileUpload defaultFiles={payload.page_image} onChange={setPageImage} />
        </Stack>
      </Stack>
    </StyledForm>

  );
}
