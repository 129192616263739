import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react'
import { useTranslation } from 'react-i18next';
import Cropper from './cropper';

ImageCropper.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCrop: PropTypes.func
};

function ImageCropper({ image, open, onClose, onCrop }) {
  const {t} = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{
        margin: '1rem',
      }}
    >
      <DialogTitle id="alert-dialog-title">{t('crop_image')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Cropper
            handleClose={onClose}
            src={image}
            getCroppedFile={onCrop}
          />
        </DialogContentText>
      </DialogContent>
    </Dialog >
  )
}
export default ImageCropper;