import { collection, doc, getDocs, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { db } from "../firebaseConfig";

export const useFetchCategories = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState(null);

    const reload = () => {
        setIsLoading(true);

        const fetchData = async () => {
            const categoriesRef = collection(db, "categories");
            const snapshot = await getDocs(categoriesRef);
            const categories = snapshot.docs.map(doc => doc.data());
            setCategories(categories);
        };

        fetchData().catch(setError).finally(() => setIsLoading(false));
    };

    useEffect(() => {
        reload();
    }, []);

    return { categories, reload, isLoading, error };
};

export const useFetchSubCategories = (idCategory) => {
    const [isLoading, setIsLoading] = useState(false);
    const [subCategories, setSubCategories] = useState([]);
    const [error, setError] = useState(null);

    const reload = useCallback(() => {
        setIsLoading(true);

        const fetchData = async () => {
            const subCategoriesRef = collection(db, "categories", idCategory, "categories");
            const snapshot = await getDocs(subCategoriesRef);
            const subCategories = snapshot.docs.map(doc => doc.data());
            setSubCategories(subCategories);
        };

        fetchData().catch(setError).finally(() => setIsLoading(false));
    }, [idCategory]);

    useEffect(() => {
        reload();
    }, [idCategory, reload]);

    return { subCategories, reload, isLoading, error };
};

export async function createCategory(payload) {
    const categoryId = uuidv4();
    payload.id = categoryId;
    return setDoc(doc(db, 'categories', categoryId), payload);
}

export async function createSubCategory(idCategory, payload) {
    const subCategoryId = uuidv4();
    payload.id = subCategoryId;
    return setDoc(doc(db, 'categories', idCategory, 'categories', subCategoryId), payload)
} 

export async function updateCategory(idCategory, payload) {
    return updateDoc(doc(db, "categories", idCategory), payload);
}

export async function updateSubCategory(idCategory, idSubCategory, payload) {
    return updateDoc(doc(db, "categories", idCategory, "categories", idSubCategory), payload);
}

export async function deleteCategory(idCategory) {
    const snapshot = await getDocs(collection(db, 'categories'), idCategory);
    const subCategories = snapshot.docs.map(doc => doc.data());

    await Promise.all(subCategories.map(
        subcat => deleteDoc(doc(db, 'categories', idCategory, 'categories', subcat.id))
    ));
    return deleteDoc(doc(db, "categories", idCategory));
}

export async function deleteSubCategory(idCategory, idSubCategory) {
    return deleteDoc(doc(db, "categories", idCategory, "categories", idSubCategory));
}