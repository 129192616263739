import {HelmetProvider} from 'react-helmet-async';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {ruRU} from '@mui/x-date-pickers/locales';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ru';
// routes
import "./i18n";
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import {StyledChart} from './components/chart';
import ScrollToTop from './components/scroll-to-top';

import './App.css';
// ----------------------------------------------------------------------
export default function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}
                              adapterLocale={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
            <HelmetProvider>
                <ThemeProvider>
                    <ScrollToTop/>
                    <StyledChart/>
                    <Router/>
                </ThemeProvider>
            </HelmetProvider>
        </LocalizationProvider>

    );
}
