import {useEffect, useState} from "react";
import {collection, getDocs, query, Timestamp, doc, writeBatch} from "firebase/firestore";
import {v4 as uuidv4} from "uuid";
import {db} from "../firebaseConfig";


export const useFetchUserChats = (userId) => {
    const [isLoading, setIsLoading] = useState(false);
    const [chats, setChats] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!userId) return;

        setIsLoading(true);
        const fetchData = async () => {
            const collectionName = `chats/${userId}/chats`;

            const chatsRef = collection(db, collectionName);
            const q = query(chatsRef)
            const querySnapshot = await getDocs(q);
            let chats = querySnapshot.docs.map(doc => doc.data());
            chats = chats.sort((a, b) => {
                const lastUpdateA = a.lastUpdate ? a.lastUpdate.toMillis() : Timestamp.now().toMillis();
                const lastUpdateB = b.lastUpdate ? b.lastUpdate.toMillis() : Timestamp.now().toMillis();
                return lastUpdateB - lastUpdateA;
            });

            setChats(chats);
        };

        fetchData().catch(setError).finally(() => setIsLoading(false));
    }, [userId]);

    return {chats, isLoading, error};
}

export async function sendMessage(message, chatId, userId, otherId) {
    function updateMessagesCollection(batch) {
        const messageId = uuidv4();
        const messagePayload = {
            id: messageId,
            createdAt: Timestamp.now(),
            senderId: userId,
            message
        }
        const coachMessagesRef = doc(collection(db, "chats", userId, 'chats', otherId, 'messages'), messageId);
        const otherMessagesRef = doc(db, "chats", otherId, 'chats', userId, 'messages', messageId);
        batch.set(coachMessagesRef, messagePayload);
        batch.set(otherMessagesRef, messagePayload);
    }

    function updateChatLastMessage(batch) {
        const coachChatRef = doc(db, "chats", userId, 'chats', otherId);
        const otherChatRef = doc(db, "chats", otherId, 'chats', userId);
        batch.update(coachChatRef, {lastMessage: message, lastUpdate: Timestamp.now()});
        batch.update(otherChatRef, {lastMessage: message, lastUpdate: Timestamp.now()});
    }

    const batch = writeBatch(db);

    updateMessagesCollection(batch);

    updateChatLastMessage(batch)
    await batch.commit();
}