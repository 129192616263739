// ImagePreviewPage.js
import React from 'react';
import { useParams } from 'react-router-dom';

const ImagePreviewPage = () => {
  const { imageUrl } = useParams();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <img src={decodeURIComponent(imageUrl)} alt="Certificate" style={{ width: '700px', height: 'auto' }} />
    </div>
  );
};

export default ImagePreviewPage;
