import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import UserContext from '../../contexts/UserContext';

RestrictedByRole.propTypes = {
  unauthorized: PropTypes.array,
  children: PropTypes.any
};

export default function RestrictedByRole({unauthorized, children}) {
    const {user} = useContext(UserContext);
    const isAuthorized = !user.userRoles.some(role => unauthorized.includes(role));
    const isAdmin = user.userRoles.includes('ADMIN');

    return (isAdmin || isAuthorized) ? children ?? <Outlet /> : <Navigate to="/404" replace/>;
};
