import { PropTypes } from "prop-types";
import { useDropzone } from "react-dropzone";
import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ImageCropper from "../image-cropper";
import { dataURLtoFile } from "../../utils/dataUrltoFile";


const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    transition: 'border .3s ease-in-out',
    textAlign: 'center',
};

const activeStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

const thumbsStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',
    padding: '1rem 0'
}

const thumbsStyleColumn = {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '1rem',
    padding: '1rem 0'
}

const thumbStyle = {
    position: 'relative',
    backgroundColor: '#f0f0f0',
}

const thumbImageStyle = {
    borderRadius: '1rem',
    height: '200px',
    width: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
}

const removeButtonStyle = {
    position: "absolute",
    right: 4,
    top: 4,
}

FileUpload.propTypes = {
    preview: PropTypes.bool,
    defaultFiles: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object
    ]),
    edit: PropTypes.bool,
    maxFiles: PropTypes.number,
    multi: PropTypes.bool,
    maxSize: PropTypes.number,
    onChange: PropTypes.func,
}

FileUpload.defaultProps = {
    preview: true,
    defaultFiles: [],
    edit: false,
    maxFiles: 1,
    multi: false,
    maxSize: Infinity,
    onChange: null,
}

function FileUpload({ preview, defaultFiles, edit, maxFiles, multi, maxSize, onChange }) {
    if (multi) maxFiles = Infinity;
    if (edit) maxFiles = 1;

    const { t } = useTranslation();
    const [files, setFiles] = useState([]);
    const [fileToCrop, setFileToCrop] = useState(null);

    const { getRootProps, getInputProps, fileRejections, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept: { 'image/*': [] },
        maxFiles,
        maxSize: maxSize * 1024 * 1024,
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length === 0) return;

            const newFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }));

            if (edit) {
                setFileToCrop(newFiles[0]);
            } else {
                setFiles(newFiles);
            }
        }
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const removeFile = (file) => {
        setFiles(files.filter(f => f !== file));
    }

    const handleCrop = (dataurl) => {
        const filename = fileToCrop.name;
        const croppedFile = dataURLtoFile(dataurl, filename);
        croppedFile.preview = URL.createObjectURL(croppedFile);
        setFiles([croppedFile]);
        setFileToCrop(null);
    }

    useEffect(() => {
        if (defaultFiles) {

            let newFiles = [];
            if (typeof defaultFiles === 'object' && defaultFiles.length > 0) {
                if (defaultFiles.every(file => typeof file === 'string')) {
                    newFiles = defaultFiles.map((file, index) => ({
                        name: `default-${index}`,
                        preview: file
                    }));
                } else if (defaultFiles.every(file => file instanceof File)) {
                    newFiles = defaultFiles.map(file => Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    }));
                } else {
                    return;
                }
            } else if (typeof defaultFiles === 'string') {
                newFiles = [{ name: 'default', preview: defaultFiles }];
            } else {
                return;
            }
            setFiles(newFiles);
        }
    }, [defaultFiles]);

    useEffect(() => {
        if (onChange && files) {
            if (maxFiles === 1 && !multi) {
                onChange(files[0]);
            } else {
                onChange(files);
            }
        }
    }, [files]);

    const thumbs = files.map(file => (
        <div key={file.name} style={thumbStyle}>
            <IconButton
                style={removeButtonStyle}
                onClick={() => removeFile(file)}
            >
                <CloseIcon />
            </IconButton>
            <img src={file.preview} style={thumbImageStyle} alt={file.name} />
        </div>
    ));

    const fileRejectionMessages = fileRejections.map(({ file, errors }) => (
        <div key={file.name}>
            {errors.map((e) => {
                switch (e.code) {
                    case 'file-too-large': {
                        return (
                            <em key={e.code} style={{ color: 'red' }}>
                                {t('File is larger than :size mb').replace(':size', maxSize)} <br />
                            </em>
                        );
                    }
                    case 'too-many-files': {
                        return (
                            <em key={e.code} style={{ color: 'red' }}>
                                {t('Too many files: {{name}}', { name: file.name })} <br />
                            </em>
                        )
                    }
                    default: {
                        return (
                            <em key={e.code} style={{ color: 'red' }}>
                                {t(JSON.stringify(e))} <br />
                            </em>
                        );
                    }
                }
            })}
        </div>
    ));

    const requirementMessages = maxSize !== Infinity ? (
        <span>
            ({maxSize !== Infinity ? t('Maximum size: :size mb').replace(':size', maxSize) : ''})
        </span>
    ) : null;

    const filesNames = files.map(file => file.name).join(', ');

    return (
        <section>
            <ImageCropper
                open={fileToCrop !== null}
                onClose={() => setFileToCrop(null)}
                image={fileToCrop?.preview}
                onCrop={handleCrop}
            />
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p style={{margin: 4}}>{t('Drag and drop your images here.')}</p>
                {requirementMessages}
                {(!preview && filesNames) && <p style={{margin: 4}}>{t('Files:')} {filesNames}</p>}
                {fileRejectionMessages}
            </div>
            {
                (files.length > 0 && preview) &&
                <aside style={files.length === 1 ? thumbsStyleColumn : thumbsStyle}>
                    {thumbs}
                </aside>
            }
        </section>
    )
}

export default FileUpload;