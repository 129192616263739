export async function uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(`https://api.cloudinary.com/v1_1/dtznarddo/upload?upload_preset=skgliut9`, {
      method: 'POST',
      body: formData,
    });

    const responseBody = await response.json();
    return responseBody.url;
}