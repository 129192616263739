import { Helmet } from 'react-helmet-async';
import { useContext, useState } from 'react';

// @mui
import { Grid, Container, Typography, Stack, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ShareIcon from '@material-ui/icons/Share';
// components
import {
  useCountPrograms,
  useFetchLastProgramsStats,
  useFetchCoachProgRegStats,
  useCountProgReg,
} from '../services/programs_service';
import {
  useCountSessions,
  useFetchLastSessionsStats,
  useFetchCoachLastSessionsStats,
} from '../services/sessions_service';
import { useFetchLastUsersStats, useCountUsers } from '../services/users_service';
import { useCountCoaches, useFetchLastCoachesStats } from '../services/coaches_service';
import UserContext from '../contexts/UserContext';

// sections
import { CurveChart, AppWidgetSummary } from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>MMCX | {t('Dashboard')} </title>
      </Helmet>
      {user.userRoles.includes('ADMIN') ? <AdminDashboard /> : <CoachDashboard />}
    </>
  );
}

function AdminDashboard() {
  const { t } = useTranslation();

  const { count: numberOfPrograms } = useCountPrograms();
  const { count: numberOfSessions } = useCountSessions();
  const { count: numberOfUsers } = useCountUsers();
  const { count: numberOfCoaches } = useCountCoaches();
  const { data: usersStats, isLoading: isLoadingUsersStats } = useFetchLastUsersStats();
  const { data: programsStats, isLoading: isLoadingProgramsStats } = useFetchLastProgramsStats();
  const { data: sessionsStats, isLoading: isLoadingSessionsStats } = useFetchLastSessionsStats();
  const { data: coachesStats, isLoading: isLoadingCoachesStats } = useFetchLastCoachesStats();
  const [isInviting, setIsInviting] = useState(false);

  const handleInviteClick = async () => {
    try {
      setIsInviting(true);

      await inviteUser();
      setIsInviting(false);
    } catch (error) {
      console.error('Error inviting user:', error);
      setIsInviting(false);
    }
  };

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4">{t('welcome_message')}</Typography>
        <Button
          variant="contained"
          startIcon={<ShareIcon />}
          onClick={handleInviteClick}
          disabled={isInviting}
          style={{ width: '130px' }}
        >
          {isInviting ? t('Inviting...') : t('Invite')}
        </Button>
      </Stack>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            title={t('programs')}
            total={numberOfPrograms}
            icon={{ src: '/assets/icons/dashboard/Work.svg' }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            title={t('sessions')}
            total={numberOfSessions}
            color="info"
            icon={{ src: '/assets/icons/dashboard/Group.svg', size: 32 }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            title={t('coaches')}
            total={numberOfCoaches}
            color="info"
            icon={{ src: '/assets/icons/dashboard/2-User.svg' }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            title={t('users')}
            total={numberOfUsers}
            color="info"
            icon={{ src: '/assets/icons/dashboard/3-User.svg' }}
          />
        </Grid>

        {!isLoadingUsersStats && (
          <Grid item xs={12} md={6}>
            <CurveChart
              title={t('created_users')}
              chartLabels={usersStats.dates}
              chartData={[
                {
                  name: t('series'),
                  type: 'area',
                  fill: 'gradient',
                  data: usersStats.counts,
                },
              ]}
              actionLabel={t('created_users')}
            />
          </Grid>
        )}

        {!isLoadingProgramsStats && (
          <Grid item xs={12} md={6}>
            <CurveChart
              title={t('created_programs')}
              chartLabels={programsStats.dates}
              chartData={[
                {
                  name: t('series'),
                  type: 'area',
                  fill: 'gradient',
                  data: programsStats.counts,
                },
              ]}
              actionLabel={t('created_programs')}
            />
          </Grid>
        )}

        {!isLoadingSessionsStats && (
          <Grid item xs={12} md={6}>
            <CurveChart
              title={t('hosted_sessions')}
              chartLabels={sessionsStats.dates}
              chartData={[
                {
                  name: t('series'),
                  type: 'area',
                  fill: 'gradient',
                  data: sessionsStats.counts,
                },
              ]}
              actionLabel={t('hosted_sessions')}
            />
          </Grid>
        )}

        {!isLoadingCoachesStats && (
          <Grid item xs={12} md={6}>
            <CurveChart
              title={t('created_coaches')}
              chartLabels={coachesStats.dates}
              chartData={[
                {
                  name: t('series'),
                  type: 'area',
                  fill: 'gradient',
                  data: coachesStats.counts,
                },
              ]}
              actionLabel={t('created_coaches')}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

function CoachDashboard() {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const { count: numberOfPrograms } = useCountPrograms(user.id);
  const { count: numberOfSessions } = useCountSessions(user.id);
  const { count: numberOfRegistrations } = useCountProgReg(user.id);
  const { data: coachSessionsStats, isLoading: isLoadingCoachSessionsStats } = useFetchCoachLastSessionsStats(user.id);
  const { data: coachesProgRegStats, isLoading: isLoadingCoachesProgRegStats } = useFetchCoachProgRegStats(user.id);
  const [isInviting, setIsInviting] = useState(false);

  const handleInviteClick = async () => {
    try {
      setIsInviting(true);
      // Example: sending an invitation
      await inviteUser();
      setIsInviting(false);
    } catch (error) {
      console.error('Error inviting user:', error);
      setIsInviting(false);
    }
  };

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4">{t('welcome_message')}</Typography>
        <Button
          variant="contained"
          startIcon={<ShareIcon />}
          onClick={handleInviteClick}
          disabled={isInviting}
          style={{ width: '130px' }}
        >
          {isInviting ? t('Inviting...') : t('Invite')}
        </Button>
      </Stack>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AppWidgetSummary
            title={t('programs')}
            total={numberOfPrograms}
            icon={{ src: '/assets/icons/dashboard/Work.svg' }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <AppWidgetSummary
            title={t('sessions')}
            total={numberOfSessions}
            color="info"
            icon={{ src: '/assets/icons/dashboard/Group.svg', size: 32 }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <AppWidgetSummary
            title={t('programs_registration')}
            total={numberOfRegistrations}
            color="info"
            icon={{ src: '/assets/icons/dashboard/3-User.svg' }}
          />
        </Grid>

        {!isLoadingCoachSessionsStats && (
          <Grid item xs={12} md={6}>
            <CurveChart
              title={t('hosted_sessions')}
              chartLabels={coachSessionsStats?.dates}
              chartData={[
                {
                  type: 'area',
                  fill: 'gradient',
                  data: coachSessionsStats?.counts,
                },
              ]}
              actionLabel={t('hosted_sessions')}
            />
          </Grid>
        )}

        {!isLoadingCoachesProgRegStats && (
          <Grid item xs={12} md={6}>
            <CurveChart
              title={t('programs_registration')}
              chartLabels={coachesProgRegStats.dates}
              chartData={[
                {
                  type: 'area',
                  fill: 'gradient',
                  data: coachesProgRegStats.counts,
                },
              ]}
              actionLabel={t('programs_registration')}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

function inviteUser() {
  try {
    if (navigator.share) {
      navigator.share({
        title: 'Check out MMCX',
        text: 'Join us on MMCX to explore exciting features!',
        url: 'https://example.com',
      });
      return;
    }

    alert('Your browser does not support sharing. Please use the native sharing feature of your device.');
  } catch (error) {
    console.error('Error inviting user:', error);
    throw error;
  }
}
