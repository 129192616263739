import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import {Card, CardHeader, Box} from '@mui/material';
import dayjs from "dayjs";
// components
import {useChart} from '../../../components/chart';
import {getSelectedLanguage} from "../../../services/language_service";

// ----------------------------------------------------------------------

CurveChart.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    chartData: PropTypes.array.isRequired,
    chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    actionLabel: PropTypes.string
};

export default function CurveChart({title, subheader, chartLabels, chartData, actionLabel, ...other}) {
    const chartOptions = useChart({
        plotOptions: {bar: {columnWidth: '16%'}},
        yAxis: {stepSize: 1},
        fill: {type: chartData.map((i) => i.fill)},
        labels: chartLabels.map(label => dayjs(label).locale(getSelectedLanguage()).format('DD MMM')),
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y) => {
                    if (typeof y !== 'undefined') {
                        return `${y.toFixed(0)} ${actionLabel}`;
                    }
                    return y;
                },
            },
        },
    });

    return (
        <Card {...other}>
            <CardHeader title={title} subheader={subheader}/>
            <Box sx={{p: 3, pb: 1}} dir="ltr">
                <ReactApexChart type="line" series={chartData} options={chartOptions} height={364}/>
            </Box>
        </Card>
    );
}
