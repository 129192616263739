import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  DialogActions,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  Alert,
  DialogContent,
  TextField,
  Popover,
  IconButton,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
// components
import i18n from '../i18n';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { createCategory, updateCategory, useFetchCategories, deleteCategory } from '../services/categories_service';
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [{ id: 'name', label: i18n.t('Name'), alignRight: false }, {}];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CategoriesPage() {
  const navigate = useNavigate();

  const { categories, reload: reloadCategories } = useFetchCategories();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [isCreationDialogOpen, setCreationDialogOpen] = useState(false);

  const [editingCategory, setEditingCategory] = useState(null);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  //  const [selectedCoachId, setSelectedCoachId] = useState(null);
  const [selectedCategoryIdForDelete, setSelectedCategoryIdForDelete] = useState(null);

  const { t } = useTranslation();

  const handleOpenMenu = (event, category) => {
    setSelectedCategoryIdForDelete(category.id);
    setEditingCategory({ anchor: event.currentTarget, category });
  };

  const handleCloseMenu = () => {
    setEditingCategory(null);
  };

  const handleOpenDeleteDialog = (id) => {
    setSelectedCategoryIdForDelete(id);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedCategoryIdForDelete) {
      try {
        // Call the deleteCoach function passing the selectedCoachIdForDelete
        await deleteCategory(selectedCategoryIdForDelete);
        reloadCategories();
      } catch (error) {
        console.error('Error deleting coach:', error);
      }

      handleCloseDeleteDialog();
      handleCloseMenu();
    }
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleToggleCreateDialog = () => {
    setCreationDialogOpen((prev) => !prev);
  };

  const handleEditDialogSubmit = () => {
    reloadCategories();
    handleToggleCreateDialog();
    handleCloseMenu();
  };

  const handleClickDelete = (id) => {
    deleteCategory(id).then(() => {
      reloadCategories();
      handleCloseMenu();
    });
  };

  const navigateToDetailsPage = (categoryId) => {
    navigate(`/dashboard/categories/${categoryId}`);
  };

  const findCategoryByName = (name) => {
    if (categories) {
      return categories.find((val) => val.name === name) || -1;
    }
    return -1;
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categories.length) : 0;

  const filtered = applySortFilter(categories, getComparator(order, orderBy), filterName);

  const isNotFound = !filtered.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> {t('Categories')} </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('Categories')}
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleToggleCreateDialog}>
            {t('New Category')}
          </Button>
        </Stack>

        <Card>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={categories.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" style={{ cursor: 'pointer' }}>
                        <TableCell align="left" style={{ width: '100%' }} onClick={() => navigateToDetailsPage(id)}>
                          <Typography variant="subtitle2" noWrap>
                            {name}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => handleOpenMenu(event, { id, name })}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            {t('Not found')}
                          </Typography>

                          <Typography variant="body2">
                            {t('No results found for')} &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> {t('Try checking for typos or using complete words')}
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={categories.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('Row per page:')}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(editingCategory)}
        anchorEl={editingCategory?.anchor}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 175,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Button onClick={handleToggleCreateDialog}>
          <MenuItem sx={{ width: 150, display: 'flex', alignItems: 'center' }}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            {t('Edit')}
          </MenuItem>
        </Button>
        <Button onClick={() => handleOpenDeleteDialog(editingCategory.category.id)}>
          <MenuItem sx={{ width: 140, display: 'flex', alignItems: 'center', color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            {t('Delete')}
          </MenuItem>
        </Button>
      </Popover>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{t('Confirm Delete')}</DialogTitle>
        <DialogContent>{t('Are you sure you want to delete this category?')}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>{t('Cancel')}</Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>

      <CategoryEditDialog
        onSubmit={handleEditDialogSubmit}
        onClose={handleToggleCreateDialog}
        open={isCreationDialogOpen}
        nameChecker={findCategoryByName}
        category={editingCategory?.category}
      />
    </>
  );
}

function CategoryEditDialog({ onSubmit, onClose, open, nameChecker, category }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const nameRef = useRef();

  const isUpdate = Boolean(category);

  const handleClose = onClose;
  const handleSubmit = (event) => {
    event.preventDefault();
    const name = nameRef.current.value;
    setError(null);

    if (nameChecker(name) !== -1) {
      setError(t('This category name already exists'));
      return;
    }

    setIsLoading(true);
    const submitData = async () => {
      if (!isUpdate) {
        createCategory({ name })
          .then(onSubmit)
          .catch((error) => setError(error.message))
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        updateCategory(category.id, { name })
          .then(onSubmit)
          .catch((error) => setError(error.message))
          .finally(() => {
            setIsLoading(false);
          });
      }
    };
    submitData();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{t(isUpdate ? 'Update Category' : 'Create Category')}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Stack spacing={3} mb={3}>
            {error && <Alert severity="error">{error}</Alert>}
            <TextField
              name="name"
              labelId="name-label"
              inputRef={nameRef}
              disabled={isLoading}
              placeholder={t('Name')}
              defaultValue={category?.name}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <LoadingButton fullWidth size="large" onClick={handleClose} variant="outlined" disabled={isLoading}>
              {t('Cancel')}
            </LoadingButton>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isLoading}
              disabled={isLoading}
            >
              {t(isUpdate ? 'Update' : 'create')}
            </LoadingButton>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
}

CategoryEditDialog.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  nameChecker: PropTypes.func,
  category: PropTypes.object,
};
