import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
// @mui
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  Container,
  InputAdornment,
  LinearProgress,
  IconButton,
  Autocomplete,
  Chip,
} from '@mui/material';
import { Alert, LoadingButton } from '@mui/lab';
import { Country, State, City } from 'country-state-city';

import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { createCoach } from '../services/coaches_service';

import { uploadFile } from '../utils/fileUploader';
import Iconify from '../components/iconify';

import FileUpload from '../components/file-upload';

// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function AddCoachPage() {
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const { t } = useTranslation();

  const [image, setImage] = useState();

  const [tags, setTags] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  function navigateToCoachesPage() {
    navigate('/dashboard/coaches');
  }

  function generatePayloadFromData(data) {
    return {
      email: data.get('email'),
      password: data.get('password'),
      name: data.get('name'),
      country: selectedCountry,
      state: selectedState,
      city: selectedCity,
      profession: data.get('profession'),
    };
  }

  function attachNonFormFields(data) {
    data.created_at = Timestamp.now();
    data.updated_at = Timestamp.now();
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const payload = generatePayloadFromData(data);
    attachNonFormFields(payload);

    setError(null);

    if (!payload.email) {
      setError(t("Upload the coach's email"));
      return;
    }

    if (!payload.password) {
      setError(t("Upload the coach's password"));
      return;
    }

    if (!image) {
      setError(t("Upload the coach's image"));
      return;
    }
    if (!payload.name) {
      setError(t("Enter the coach's name"));
      return;
    }

    if (!payload.country) {
      setError(t("Enter the coach's country"));
      return;
    }

    if (!payload.state) {
      setError(t("Enter the coach's state"));
      return;
    }

    if (!payload.city) {
      setError(t("Enter the coach's city"));
      return;
    }

    if (!payload.profession) {
      setError(t("Enter the coach's profession"));
      return;
    }

    setIsLoading(true);

    const imageUrl = await uploadFile(image);
    data.set('image', imageUrl);

    createCoach(payload)
      .then(navigateToCoachesPage)
      .catch((error) => setError(error.message))
      .finally(() => setIsLoading(false));
  }

  const onChangeTags = (event, newTags) => {
    setTags(newTags);
  };

  useEffect(() => {
    const countryData = Country.getAllCountries();
    setCountries(countryData);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const stateData = State.getStatesOfCountry(selectedCountry);
      setStates(stateData);
    } else {
      setStates([]);
    }
    setSelectedState('');
    setSelectedCity('');
    setCities([]);
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      const cityData = City.getCitiesOfState(selectedCountry, selectedState);
      setCities(cityData);
    } else {
      setCities([]);
    }
    setSelectedCity('');
  }, [selectedState]);

  return (
    <>
      {isLoading && <LinearProgress />}
      <Helmet>
        <title> {t('create_a_new_coach')} | MMCX </title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              {t('create_a_new_coach')}
            </Typography>

            <form onSubmit={handleSubmit}>
              <Stack spacing={3} mb={3}>
                <TextField name="name" label={t('Name')} />
                <TextField name="email" label={t('Email address')} />
                <TextField name="phone" label={t('Phone')} />

                <TextField
                  name="password"
                  label={t('Password')}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField name="profession" label={t('Profession')} />
                <TextField
                  select
                  label={t('Select Country')}
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    {t('Select Country')}
                  </MenuItem>
                  {countries.map((country) => (
                    <MenuItem key={country.isoCode} value={country.isoCode}>
                      {t(country.name)}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  label={t('State')}
                  value={selectedState}
                  onChange={(e) => setSelectedState(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    {t('Select State')}
                  </MenuItem>
                  {states.map((state) => (
                    <MenuItem key={state.isoCode} value={state.isoCode}>
                      {t(state.name)}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  label={t('City')}
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    {t('Select City')}
                  </MenuItem>
                  {(cities || []).length ? (
                    cities.map((city) => (
                      <MenuItem key={city.name} value={city.name}>
                        {t(city.name)}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled>
                      No cities available
                    </MenuItem>
                  )}
                </TextField>
                <FileUpload maxSize={10} edit onChange={setImage} />

                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  value={tags}
                  onChange={onChangeTags}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip key={option} variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => <TextField {...params} placeholder={t('tags')} />}
                />
              </Stack>
              {error && <Alert severity="error">{error}</Alert>}
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
                disabled={isLoading}
              >
                {t('create')}
              </LoadingButton>
            </form>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
