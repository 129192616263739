import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { filter } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';

// @mui
import {
  Checkbox,
  Stack,
  Grid,
  Avatar,
  ToggleButtonGroup,
  ToggleButton,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
  LinearProgress,
  Typography,
  Card,
  Box,
  TablePagination,
  TableContainer,
  Table,
  TableBody,
  Button,
  TableCell,
  TableRow,
  Paper,
  FormControl,
  Select,
  DialogContentText,
  InputLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTranslation } from 'react-i18next';
import generatePDF from 'react-to-pdf';
import { Timestamp } from 'firebase/firestore';
import i18n from '../i18n';
import Scrollbar from '../components/scrollbar';
import { useFetchAllUsers, useFetchEnrollmentStatus } from '../services/users_service';
import { UserListHead } from '../sections/@dashboard/user';
import { CurveChart } from '../sections/@dashboard/app';
import {
  updateUserProgramStatusById,
  useCountPrograms,
  useFetchProgramEnrollStats,
  useFetchProgramUnenrollStats,
} from '../services/programs_service';
import Iconify from '../components/iconify';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'Member', label: i18n.t('Members'), alignRight: false },
  { id: 'Status', label: i18n.t('Status'), alignRight: false },
  { id: 'Date Enrolled', label: i18n.t('Date Enrolled'), alignRight: false },
  { id: 'Enrolment', label: i18n.t('Enrolment'), alignRight: false },
  {},
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));
export default function ManagePrograms() {
  const { id } = useParams();
  const { t } = useTranslation();
  const containerRef = React.useRef(null);
  const { count: numberOfPrograms, isLoading } = useCountPrograms();
  const { enrolledUsers, unenrolledUsers, isLoading: statusLoading, error } = useFetchEnrollmentStatus(id);
  const [chartName, setChartName] = useState('view_all');
  const [totalUsersPercentage, setTotalUsersPercentage] = useState(null);
  const [count, setCount] = useState(0);

  const filterUniqueUsers = (users) => {
    const uniqueUsers = [];

    // Ensure that users is defined before attempting to iterate
    if (users) {
      users.forEach((user) => {
        const isUnique = !uniqueUsers.some((existingUser) => existingUser.user_id === user.user_id);

        if (isUnique) {
          uniqueUsers.push(user);
        }
      });
    }

    return uniqueUsers;
  };

  const uniqueEnrolledUsers = filterUniqueUsers(enrolledUsers);
  const uniqueUnEnrolledUsers = filterUniqueUsers(unenrolledUsers);
  const uniqueUnEnrolledUsersCount = uniqueUnEnrolledUsers.length;
  const uniqueEnrolledUserCount = uniqueEnrolledUsers.length;
  const numberOfRegisteredPrograms = uniqueEnrolledUserCount + uniqueUnEnrolledUsersCount;

  useEffect(() => {
    if (!statusLoading && numberOfRegisteredPrograms > 0) {
      const percentage = Math.round((uniqueEnrolledUserCount / numberOfRegisteredPrograms) * 100);
      setTotalUsersPercentage(percentage);
    }
  }, [statusLoading, uniqueEnrolledUserCount, numberOfRegisteredPrograms]);

  function download() {
    generatePDF(containerRef, {
      filename: 'page.pdf',
      overrides: {
        canvas: {
          useCORS: true,
        },
      },
    });
  }

  const handleTabChange = (value) => {
    setChartName(value);
  };

  const buttonStyle = {
    width: '185px',
  };

  const downloadButtonStyle = {
    ...buttonStyle,
    background: '#007AFF',
    color: '#ffffff',
  };

  return (
    <>
      <Helmet>
        <title>{t('Program Details')}</title>
      </Helmet>
      {isLoading && <LinearProgress />}
      {!isLoading && (
        <Container ref={containerRef}>
          <Stack gap="20px" direction={{ xs: 'column', sm: 'row' }} alignItems={'stretch'}>
            <Card
              sx={{
                height: 'auto',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '20px',
                background: 'white',
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  p: 2,
                }}
              >
                <Typography variant="h6" color="#2196f3" sx={{ fontWeight: 'lighter' }}>
                  {t('Total Members')}
                </Typography>
                <Typography
                  variant="h2"
                  color="#2196f3"
                  sx={{ fontWeight: 'lighter', display: 'flex', alignItems: 'baseline' }}
                >
                  <span style={{ marginRight: '4px' }}>{numberOfRegisteredPrograms}</span>
                  <span
                    style={{
                      display: 'inline-block',
                      width: 'auto',
                      height: 'auto',
                      padding: '4px',
                      backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
                      color: 'green',
                      border: '1px solid',
                      borderColor: 'grey',
                      borderRadius: '25px',
                      fontSize: '0.875rem',
                      fontWeight: '700',
                      textAlign: 'center',
                      verticalAlign: 'baseline',
                      marginLeft: 'auto',
                    }}
                  >
                    <ArrowDropUpIcon
                      style={{
                        fontSize: '21px',
                        transform: 'rotate(0deg)',
                        marginLeft: '1px',
                        verticalAlign: 'middle',
                      }}
                    />
                    {totalUsersPercentage}%
                  </span>
                </Typography>
              </Box>
            </Card>

            {/* Second Card */}
            <Card
              sx={{
                height: 'auto',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '20px',
                background: 'white',
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  p: 2,
                }}
              >
                <Typography variant="h6" color="#2196f3" sx={{ fontWeight: 'lighter' }}>
                  {t('Enrolled Members')}
                </Typography>
                <Typography
                  variant="h2"
                  color="#2196f3"
                  sx={{ fontWeight: 'lighter', display: 'flex', alignItems: 'baseline' }}
                >
                  <span style={{ marginRight: '4px' }}>{uniqueEnrolledUserCount}</span>
                  <span
                    style={{
                      display: 'inline-block',
                      width: 'auto',
                      height: 'auto',
                      padding: '4px',
                      backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
                      color: 'green',
                      border: '1px solid',
                      borderColor: 'grey',
                      borderRadius: '25px',
                      fontSize: '0.875rem',
                      fontWeight: '700',
                      textAlign: 'center',
                      verticalAlign: 'baseline',
                      marginLeft: 'auto',
                    }}
                  >
                    <ArrowDropUpIcon
                      style={{
                        fontSize: '21px',
                        transform: 'rotate(0deg)',
                        marginLeft: '1px',
                        verticalAlign: 'middle',
                      }}
                    />
                    0%
                  </span>
                </Typography>
              </Box>
            </Card>

            <Card
              sx={{
                height: 'auto',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '20px',
                background: 'white',
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  p: 2,
                }}
              >
                <Typography variant="h6" color="#2196f3" sx={{ fontWeight: 'lighter' }}>
                  {t('Unenrolled Members')}
                </Typography>
                <Typography
                  variant="h2"
                  color="#2196f3"
                  sx={{ fontWeight: 'lighter', display: 'flex', alignItems: 'baseline' }}
                >
                  <span style={{ marginRight: '4px' }}>{uniqueUnEnrolledUsersCount}</span>
                  <span
                    style={{
                      display: 'inline-block',
                      width: 'auto',
                      height: 'auto',
                      padding: 4,
                      backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
                      color: '#FF0000',
                      border: '1px solid',
                      borderColor: 'grey',
                      borderRadius: 25,
                      fontSize: '0.875rem',
                      fontWeight: '700',
                      textAlign: 'center',
                      verticalAlign: 'baseline',
                      marginLeft: 'auto',
                    }}
                  >
                    <ArrowDropUpIcon
                      style={{
                        fontSize: '21px',
                        transform: 'rotate(180deg)',
                        marginLeft: '1px',
                        verticalAlign: 'middle',
                      }}
                    />
                    0%
                  </span>
                </Typography>
              </Box>
            </Card>
          </Stack>
          <Stack spacing={2} direction="row" justifyContent="flex-end" marginTop={2} marginBottom={2}>
            <Button variant="contained" style={downloadButtonStyle} onClick={() => download()}>
              {t('Download Report')}
            </Button>
          </Stack>
          <Stack spacing={2}>
            <CourseTabs onChangeTab={handleTabChange} />
            <CourseChart chartName={chartName} />
            <MemberList enrolledUsers={uniqueEnrolledUsers} statusLoading={statusLoading} />
          </Stack>
        </Container>
      )}
    </>
  );
}

export const CourseTabs = ({ onChangeTab }) => {
  const [alignment, setAlignment] = React.useState('view_all');
  const { t } = useTranslation();

  const handleChange = (event, value) => {
    if (value === null) return;
    setAlignment(value);
    onChangeTab(value);
  };

  return (
    <div>
      <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '0px' }}>
        {t('Program Members')}
      </Typography>
      <Typography color="textSecondary">{t('Manage total program members and their progress')}</Typography>

      <Stack spacing={0} direction="row">
        <ToggleButtonGroup color="primary" value={alignment} exclusive onChange={handleChange} aria-label="Platform">
          <ToggleButton
            value="view_all"
            sx={{ width: { xs: '110px', md: '170px' }, '&:hover': { backgroundColor: '#2196f3' } }}
          >
            {t('View All')}
          </ToggleButton>
          <ToggleButton
            value="enrolled"
            sx={{ width: { xs: '110px', md: '170px' }, '&:hover': { backgroundColor: '#2196f3' } }}
          >
            {t('Enrolled')}
          </ToggleButton>
          <ToggleButton
            value="unenrolled"
            sx={{ width: { xs: '110px', md: '170px' }, '&:hover': { backgroundColor: '#2196f3' } }}
          >
            {t('Unenrolled')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
    </div>
  );
};

function getUserById(userList, userId) {
  // Using Array.find to find the user with the matching user_id
  const foundUser = userList.find((user) => user.id === userId);

  // Return the found user or null if not found
  return foundUser || null;
}

export const CourseChart = ({ chartName }) => {
  const { t } = useTranslation();
  const { id: programId } = useParams();
  const { data: enrolledData, isLoading: isLoadingEnrolled, error: enrError } = useFetchProgramEnrollStats(programId);
  const {
    data: unenrolledData,
    isLoading: isLoadingUnenrolled,
    error: unEnrError,
  } = useFetchProgramUnenrollStats(programId);

  if (enrError) console.error(enrError);
  if (unEnrError) console.error(unEnrError);

  const displayedStats = React.useMemo(() => {
    if (!enrolledData || !unenrolledData) return null;
    return {
      view_all: {
        dates: enrolledData.dates,
        counts: enrolledData.counts.map((count, index) => count + unenrolledData.counts[index]),
      },
      enrolled: enrolledData,
      unenrolled: unenrolledData,
    }[chartName];
  }, [enrolledData, unenrolledData, chartName]);

  return (
    !isLoadingEnrolled &&
    !isLoadingUnenrolled &&
    enrolledData &&
    unenrolledData && (
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <CurveChart
            chartLabels={displayedStats.dates}
            chartData={[
              {
                name: t('series'),
                type: 'area',
                fill: 'gradient',
                data: displayedStats.counts,
              },
            ]}
            actionLabel={t('members')}
          />
        </Grid>
      </Grid>
    )
  );
};

export function MemberList(enrolledUsers) {
  const [filterName, setFilterName] = useState('');
  const { users, reloadUsers } = useFetchAllUsers();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [selectedEnrolledUserId, setSelectedEnrolledUserId] = useState('');

  const [orderBy, setOrderBy] = useState('name');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { t } = useTranslation();
  // const [editingProgram, setEditingProgram] = useState(null);
  const [enrolment, setEnrolment] = React.useState('');
  const [open, setOpen] = useState(false); // State for controlling dialog open/close
  const [selectedValue, setSelectedValue] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleEnrolmentChange = (event, id) => {
    setSelectedValue(event.target.value);
    setOpen(true);
    setEnrolment(event.target.value);
    setSelectedEnrolledUserId(id);
  };

  function generatePayloadFromData(data) {
    return {
      status: data.get('status'),
    };
  }

  async function handleConfirm() {
    try {
      // Reset error state and set loading state
      setError(null);
      setIsLoading(true);

      // Get the program ID from enrolledUsers prop
      const programId = selectedEnrolledUserId; // Check if enrolledUsers is defined
      handleClose();
      // Update user program data
      await updateUserProgramStatusById(programId, enrolment.toUpperCase());

      // Close the confirmation dialog
      setOpen(false);
    } catch (error) {
      // Handle any errors
      setError(error.message);
    } finally {
      // Reset loading state
      setIsLoading(false);
      window.location.reload();
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <Card>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 320, maxHeight: '70vh' }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={users.length}
              // numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {enrolledUsers.enrolledUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { id, user_id: userId, status, created_at: createdAt, progress } = row;
                const name = status;
                const selectedUser = selected.indexOf(name) !== -1;

                return (
                  <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={selectedUser}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      padding="none"
                      sx={{
                        width: '25%',
                        padding: '14px',
                      }}
                    >
                      <Stack direction="row" alignItems="center" spacing={2} style={{ marginLeft: 5 }}>
                        <Avatar alt={name} src={getUserById(users, userId)?.image} />
                        <Typography variant="subtitle2" noWrap>
                          {getUserById(users, userId)?.name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="left" sx={{ width: '25%' }}>
                      {status === 'REGISTERED' ? (
                        <span
                          style={{
                            display: 'inline-block',
                            width: 105,
                            height: 30,

                            paddingBottom: '5px',
                            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
                            color: 'green',
                            border: '1px solid',
                            borderColor: 'grey',
                            borderRadius: 25,
                            fontSize: '0.875rem',
                            fontWeight: '600',
                            textAlign: 'center',
                            verticalAlign: 'baseline',
                            marginTop: '25px',
                            marginLeft: 'auto',
                          }}
                        >
                          <FiberManualRecordIcon
                            style={{
                              fontSize: '12px',
                              color: 'green',
                              verticalAlign: 'middle',
                              paddingRight: '2px',
                            }}
                          />
                          {t('Active')}
                        </span>
                      ) : (
                        <span
                          style={{
                            display: 'inline-block',
                            width: 105,
                            height: 30,
                            paddingBottom: '5px',
                            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
                            color: 'red',
                            border: '1px solid',
                            borderColor: 'grey',
                            borderRadius: 25,
                            fontSize: '0.875rem',
                            fontWeight: '600',
                            textAlign: 'center',
                            verticalAlign: 'baseline',
                            marginTop: '25px',
                            marginLeft: 'auto',
                          }}
                        >
                          <FiberManualRecordIcon
                            style={{
                              fontSize: '12px',
                              color: 'red',
                              verticalAlign: 'middle',
                              paddingRight: '2px',
                            }}
                          />
                          {t('Inactive')}
                        </span>
                      )}
                    </TableCell>
                    <TableCell align="left" sx={{ width: '25%' }}>
                      {convertTimestampToFormattedDate(createdAt)}
                    </TableCell>
                    <TableCell align="left" sx={{ width: '25%' }}>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label">{status}</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          onChange={(event) => {
                            handleEnrolmentChange(event, id);
                          }}
                          value={enrolment}
                        >
                          <MenuItem value="Registered">Registered</MenuItem>
                          <MenuItem value="Concluded">Concluded</MenuItem>
                          <MenuItem value="Timeout">Timeout</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="h6" paragraph>
                        {t('Not found')}
                      </Typography>

                      <Typography variant="body2">
                        {t('No results found for')} &nbsp;
                        <strong>&quot;{filterName}&quot;</strong>.
                        <br /> {t('Try checking for typos or using complete words')}
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('Row per page:')}
        sx={{ minWidth: 320 }}
      />
      {/* Confirmation Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to change the enrolment status to <strong>{enrolment}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleConfirm(selectedEnrolledUserId)} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

function convertTimestampToFormattedDate(timestamp) {
  const milliseconds = timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1e6);
  const date = new Date(milliseconds);

  // Format the date in "MMM D, YYYY" format
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return formattedDate;
}
