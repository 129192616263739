import { Box, Button, ButtonGroup, Skeleton } from "@mui/material";
import { PropTypes } from "prop-types";
import { useRef, useState } from "react";
import ReactCropper from "react-cropper";

import "cropperjs/dist/cropper.css";
import { useTranslation } from "react-i18next";

Cropper.propTypes = {
    src: PropTypes.string,
    getCroppedFile: PropTypes.func
};

function Cropper({ src, getCroppedFile }) {
    const {t} = useTranslation();
    const cropperRef = useRef(null);
    const [loading, setLoading] = useState(true);

    const handleClick = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        const img = cropper.getCroppedCanvas().toDataURL();
        getCroppedFile(img);
    };

    return (
        <>
            {loading && (
                <Skeleton variant="rectangular" width={"100%"} height={300} />
            )}

            <ReactCropper
                src={src}
                style={{ height: 300, width: "100%" }}
                initialAspectRatio={1 / 1}
                aspectRatio={1 / 1}
                guides
                ready={() => {
                    setLoading(false);
                }}
                ref={cropperRef}
            />
            <Button
                style={{marginTop: '2rem', width: '100%'}}
                onClick={handleClick}
                autoFocus
                variant="contained"
            >
                {t('crop')}
            </Button>
        </>
    );
}

export default Cropper