import {
  Alert,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import Iconify from '../iconify';
import { formDataToObject } from '../../utils/formDataConverter';

const DURATION_UNIT_OPTIONS = ['Minutes', 'Hours', 'Days', 'Weeks', 'Months'];

SessionSection.propTypes = {
  currency: PropTypes.string.isRequired,
  sessionsState: PropTypes.array.isRequired,
};

export default function SessionSection({ currency, sessionsState }) {
  const { t } = useTranslation();
  const [sessions, setSessions] = sessionsState;
  const [durationUnit, setDurationUnit] = useState(DURATION_UNIT_OPTIONS[0]);
  const [error, setError] = useState(null);

  const [editingSession, setEditingSession] = useState(null);
  const [isDelDialogOpen, setDelDialogOpen] = useState(false);
  const [isUpdateDialogOpen, setUpdateDialogOpen] = useState(false);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const formData = new FormData(ev.currentTarget);
    const data = formDataToObject(formData);

    if (!data.name || !data.price || !data.duration || !durationUnit) {
      setError(t('Please complete the form before submission'));
      return;
    }

    data.id = uuidv4();
    data.duration_unit = durationUnit;
    data.duration = Number(data.duration);

    setError(null);
    setSessions((prevSessions) => [...prevSessions, data]);
    ev.target.reset();
  };

  const handleDurationUnitChange = (event) => {
    setDurationUnit(event.target.value);
  };

  const handleOpenMenu = (event, session) => {
    setEditingSession({
      anchor: event.currentTarget,
      session,
    });
  };
  const handleCloseMenu = () => setEditingSession(null);

  const handleOpenUpdateDialog = () => setUpdateDialogOpen(true);
  const handleCloseUpdateDialog = () => {
    setUpdateDialogOpen(false);
    setEditingSession(null);
  };
  const handleSubmitUpdate = (updatedData) => {
    const newSessions = [...sessions];
    newSessions[sessions.findIndex((el) => el.id === updatedData.id)] = updatedData;
    setSessions(newSessions);
    handleCloseUpdateDialog();
  };

  const handleDelete = () => setDelDialogOpen(true);
  const handleCloseDelDialog = () => setDelDialogOpen(false);
  const handleConfirmDelete = () => {
    if (editingSession) {
      setSessions((prev) => prev.filter((el) => el.id !== editingSession.session.id));
      handleCloseDelDialog();
      handleCloseMenu();
    }
  };

  return (
    <Stack spacing={3} paddingBottom={4} marginBottom={{ xs: 4, sm: 0 }} width={{ xs: '100%', sm: 'auto' }}>
      <Typography variant="h5" gutterBottom>
        {t('Modules')}
      </Typography>
      {error && (
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <Stack marginBottom={{ xs: 2, sm: 0 }} direction={{ xs: 'column', lg: 'row' }} gap={3}>
          <TextField name="name" sx={{ flex: 2 }} label={t('name')} variant="outlined" placeholder="" />

          <TextField
            name="price"
            labelId="price-label"
            label={t('Price')}
            type="number"
            sx={{ flex: 1 }}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />

          <Stack direction="row" gap={3}>
            <TextField
              sx={{ flex: 1, maxWidth: { lg: '6rem' } }}
              name="duration"
              labelId="duration-label"
              label={t('duration')}
              type={'number'}
              defaultValue={0}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
            <Select labelId="duration-type-label" value={durationUnit} onChange={handleDurationUnitChange}>
              {DURATION_UNIT_OPTIONS.map((option) => (
                <MenuItem key={option} value={option}>
                  {t(option)}
                </MenuItem>
              ))}
            </Select>
          </Stack>

          <LoadingButton type="submit" variant="outlined" size="large">
            {t('Add')}
          </LoadingButton>
        </Stack>
      </form>
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('Name')}</TableCell>
                <TableCell>{t('Price')}</TableCell>
                <TableCell>{t('duration')}</TableCell>
                <TableCell>{''}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sessions.length ? (
                sessions.map((session) => {
                  const price = `${session.price} ${currency}`;
                  const unit = session.duration_unit.toLowerCase();
                  const duration = t(`ct.${unit.substr(0, unit.length - 1)}`, { count: session.duration });
                  return (
                    <TableRow key={session.id}>
                      <TableCell>{session.name}</TableCell>
                      <TableCell>{price}</TableCell>
                      <TableCell>{duration}</TableCell>
                      <TableCell align="right">
                        <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, session)}>
                          <Iconify icon={'eva:more-vertical-fill'} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell sx={{ textAlign: 'center', color: 'GrayText', fontStyle: 'italic' }} colSpan={3}>
                    {t('Empty')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Popover
        open={Boolean(editingSession)}
        anchorEl={editingSession?.anchor}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 175,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Button onClick={handleOpenUpdateDialog}>
          <MenuItem sx={{ width: 150, display: 'flex', alignItems: 'center' }}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            {t('Edit')}
          </MenuItem>
        </Button>
        <Button onClick={handleDelete}>
          <MenuItem sx={{ width: 140, display: 'flex', alignItems: 'center', color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            {t('Delete')}
          </MenuItem>
        </Button>
      </Popover>

      {/* Delete Confirmation dialog */}
      <Dialog open={isDelDialogOpen} onClose={handleCloseDelDialog}>
        <DialogTitle>{t('Confirm Delete')}</DialogTitle>
        <DialogContent>{`${t('Are you sure you want to delete this')} ${t('session')} ?`}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelDialog}>{t('Cancel')}</Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Update dialog */}
      <UpdateDialog
        open={isUpdateDialogOpen}
        onClose={handleCloseUpdateDialog}
        onSubmit={handleSubmitUpdate}
        session={editingSession?.session}
      />
    </Stack>
  );
}

function UpdateDialog({ open, onClose, onSubmit, session }) {
  const { t } = useTranslation();
  const [durationUnit, setDurationUnit] = useState(session?.duration_unit || DURATION_UNIT_OPTIONS[0]);

  const handleDurationUnitChange = (event) => {
    setDurationUnit(event.target.value);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const data = formDataToObject(new FormData(ev.currentTarget));
    data.id = session.id;
    data.duration = Number(data.duration);
    data.duration_unit = durationUnit;
    onSubmit(data);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t('Update a session')}</DialogTitle>

        <Stack p={4} spacing={3}>
          <TextField
            name="name"
            label={t('name')}
            variant="outlined"
            placeholder=""
            defaultValue={session?.name || ''}
          />

          <TextField
            style={{ flex: 1 }}
            name="price"
            labelId="price-label"
            label={t('Price')}
            type="number"
            defaultValue={session?.price || 0}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />

          <TextField
            name="duration"
            labelId="duration-label"
            label={t('duration')}
            type={'number'}
            defaultValue={session?.duration}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />

          <FormControl fullWidth>
            <InputLabel id="duration-type-labe">{t('Frequency')}</InputLabel>
            <Select
              labelId="duration-type-label"
              value={durationUnit}
              label={t('Frequency')}
              onChange={handleDurationUnitChange}
            >
              {DURATION_UNIT_OPTIONS.map((option) => (
                <MenuItem key={option} value={option}>
                  {t(option)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <DialogActions sx={{ gap: 2, margin: 1 }}>
          <LoadingButton onClick={onClose} variant="outlined">
            {t('Cancel')}
          </LoadingButton>
          <LoadingButton type="submit" variant="contained">
            {t('Update')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
