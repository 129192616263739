export function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[arr.length - 1]); 
    let n = bstr.length; 
    const u8arr = new Uint8Array(n);
    
    // eslint-disable-next-line no-plusplus
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    const file = new File([u8arr], filename, {type:mime});
    
    return file;
};
