const validLanguages = ['en', 'ru'];

export function getSelectedLanguage() {
    const defaultLanguage = 'ru';
    const language = localStorage.getItem("language");

    if (!validLanguages.includes(language)) {
        return defaultLanguage;
    }

    return language;
}


export function setSelectedLanguage(language) {
    if (!validLanguages.includes(language)) {
        return;
    }

    localStorage.setItem("language", language);
}