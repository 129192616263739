import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { Stack, TextField, MenuItem, Typography, Container, LinearProgress, Autocomplete, Chip } from '@mui/material';
import { Alert, LoadingButton } from '@mui/lab';
import { Country, State, City } from 'country-state-city';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Timestamp } from 'firebase/firestore';
import { Helmet } from 'react-helmet-async';
import { updateCoach } from '../services/coaches_service';
import FileUpload from '../components/file-upload';

import { uploadFile } from '../utils/fileUploader';
import { useFetchItem } from '../services/item_fetch_service';

// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function EditUserPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: user, isLoading: isInitialLoading } = useFetchItem('users', id);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const { t } = useTranslation();

  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  function navigateToUsersPage() {
    navigate('/dashboard/users');
  }

  function generatePayloadFromData(data) {
    return {
      email: data.get('email'),
      image: data.get('image'),
      name: data.get('name'),
      country: data.get('country'),
      city: data.get('city'),
    };
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    setError(null);

    if (!data.get('email')) {
      setError(t("Upload the user's email"));
      return;
    }

    if (!data.get('name')) {
      setError(t("Enter the user's name"));
      return;
    }

    if (!data.get('country')) {
      setError(t("Enter the user's country"));
      return;
    }

    if (!data.get('city')) {
      setError(t("Enter the user's city"));
      return;
    }

    setIsLoading(true);

    const imageUrl = image ? await uploadFile(image) : user?.image;
    data.set('image', imageUrl);

    const payload = generatePayloadFromData(data);
    payload.updated_at = Timestamp.now();

    updateCoach(id, payload)
      .then(navigateToUsersPage)
      .catch((error) => setError(error.message))
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    // Populate countries
    const countryData = Country.getAllCountries();
    setCountries(countryData);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const stateData = State.getStatesOfCountry(selectedCountry);
      setStates(stateData);
    } else {
      setStates([]);
    }
    setSelectedState('');
    setCities([]);
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      // Use the state code to get cities
      const cityData = City.getCitiesOfState(selectedCountry, selectedState);

      // Assuming cityData is an array of city objects with 'name' property

      setCities(cityData);
    } else {
      setCities([]);
    }
  }, [selectedState]);

  return isInitialLoading ? (
    <LinearProgress />
  ) : (
    <>
      {isLoading && <LinearProgress />}
      <Helmet>
        <title>{`${t('edit_user')} | ${user?.name}`}</title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              {`${t('edit_user')} | ${user?.name}`}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Stack spacing={3} mb={3}>
                <TextField name="name" label={t('Name')} defaultValue={user?.name} />
                <TextField name="email" label={t('Email address')} defaultValue={user?.email} />
                <TextField name="phone" label={t('Phone')} defaultValue={user?.phone} />
                <TextField
                  select
                  label={t('Country')}
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                >
                  <MenuItem value="">{t('Select Country')}</MenuItem>
                  {countries.map((country) => (
                    <MenuItem key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  label={t('State')}
                  value={selectedState}
                  onChange={(e) => setSelectedState(e.target.value)}
                >
                  <MenuItem value="">Select State</MenuItem>
                  {states.map((state) => (
                    <MenuItem key={state.isoCode} value={state.isoCode}>
                      {state.name}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField select label={t('City')} value={cities} onChange={(e) => setCities(e.target.value)}>
                  <MenuItem value="">Select City</MenuItem>
                  {Array.isArray(cities) ? (
                    cities.map((city) => (
                      <MenuItem key={city.isoCode} value={city.isoCode}>
                        {city.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">No cities available</MenuItem>
                  )}
                </TextField>
                <FileUpload maxSize={10} onChange={setImage} />
              </Stack>

              {error && <Alert severity="error">{error}</Alert>}
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                <LoadingButton
                  fullWidth
                  size="large"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  variant="outlined"
                  disabled={isLoading}
                >
                  {t('Cancel')}
                </LoadingButton>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  {t('Update')}
                </LoadingButton>
              </Stack>
            </form>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
