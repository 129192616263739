import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useContext, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  LinearProgress,
} from '@mui/material';
// components
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { deleteProgram, useFetchCoachPrograms } from '../services/programs_service';
import UserContext from '../contexts/UserContext';
import { formatImage } from '../utils/formatImage';

// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: i18n.t('Name'), alignRight: false },
  { id: 'description', label: i18n.t('Description'), alignRight: false },
  { id: 'packages', label: i18n.t('Packages'), alignRight: false },
  { id: 'numberOfParticipants', label: i18n.t('Participants'), alignRight: false },
  { id: 'rating', label: i18n.t('Rating'), alignRight: false },
  { id: 'tags', label: i18n.t('Tags'), alignRight: false },
  {},
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function MyProgramsPage() {
  const [orderBy, setOrderBy] = useState('created_at');
  const [editingProgram, setEditingProgram] = useState(null);
  const { user } = useContext(UserContext);
  const { programs, reloadPrograms, isLoading } = useFetchCoachPrograms(user?.id);

  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [selectedProgramIdForDelete, setSelectedProgramIdForDelete] = useState(null);

  const { t } = useTranslation();

  const handleOpenMenu = (event, id) => {
    event.preventDefault();
    setSelectedProgramIdForDelete(id);
    setEditingProgram({ anchor: event.currentTarget, id });
  };

  const handleOpenDeleteDialog = (id) => {
    setSelectedProgramIdForDelete(id);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleCloseMenu = () => {
    setEditingProgram(null);
  };

  const handleConfirmDelete = async () => {
    if (selectedProgramIdForDelete) {
      try {
        await deleteProgram(selectedProgramIdForDelete);
        handleCloseDeleteDialog();
        reloadPrograms();
      } catch (error) {
        console.error('Error deleting coach:', error);
      }

      // Close the delete confirmation dialog
      handleCloseDeleteDialog();
      handleCloseMenu();
    }
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - programs.length) : 0;

  const filtered = applySortFilter(programs, getComparator(order, orderBy), filterName);

  const isNotFound = !filtered.length && !!filterName;

  function navigateToProgramPage(id) {
    navigate(`/dashboard/programs/${id}`);
  }

  return (
    <>
      <Helmet>
        <title> {t('My Programs')} </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('My Programs')}
          </Typography>

          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} href={'/dashboard/programs/create'}>
            {t('New Program')}
          </Button>
        </Stack>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <Card>
            <UserListToolbar numSelected={0} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const {
                        id,
                        name,
                        thumbnail,
                        description,
                        packages,
                        numberOfParticipants,
                        rating,
                        tags,
                        currency,
                      } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1}>
                          <TableCell component="th" scope="row" onClick={() => navigateToProgramPage(id)}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={formatImage(thumbnail, { w: 72, h: 72 })} />
                              <Typography variant="subtitle2" xs={{overflowWrap: "break-word"}}>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left" onClick={() => navigateToProgramPage(id)}>
                            {description}
                          </TableCell>
                          <TableCell align="left" onClick={() => navigateToProgramPage(id)}>
                            <ul>
                              {packages && packages.length !== 0
                                ? packages.map((p) => (
                                    <li key={p.id}>
                                      <b>{p.name}</b>: {p.total} {currency}
                                    </li>
                                  ))
                                : t('Empty')}
                            </ul>
                          </TableCell>

                          <TableCell align="left" onClick={() => navigateToProgramPage(id)}>
                            {numberOfParticipants}
                          </TableCell>
                          <TableCell align="left" onClick={() => navigateToProgramPage(id)}>
                            {rating}
                          </TableCell>
                          <TableCell align="left" onClick={() => navigateToProgramPage(id)}>
                            {(tags ?? []).join(', ')}
                          </TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              {t('Not found')}
                            </Typography>

                            <Typography variant="body2">
                              {t('No results found for')} &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> {t('Try checking for typos or using complete words')}
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={programs.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t('Row per page:')}
            />
          </Card>
        )}
      </Container>

      <Popover
        open={Boolean(editingProgram)}
        anchorEl={editingProgram?.anchor}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 175,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Button href={`/dashboard/programs/${editingProgram?.id}/edit`}>
          <MenuItem sx={{ width: 150, display: 'flex', alignItems: 'center' }}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            {t('Edit')}
          </MenuItem>
        </Button>
        <Button onClick={() => handleOpenDeleteDialog(editingProgram?.id)}>
          <MenuItem sx={{ width: 140, display: 'flex', alignItems: 'center', color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            {t('Delete')}
          </MenuItem>
        </Button>
      </Popover>
      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{t('Confirm Delete')}</DialogTitle>
        <DialogContent>{t('Are you sure you want to delete this Program?')}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>{t('Cancel')}</Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
