import { useState, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton, Popover } from '@mui/material';
import { getSelectedLanguage, setSelectedLanguage } from '../../../services/language_service';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'ru',
    label: 'Русский',
    icon: '/assets/icons/ic_flag_ru.svg',
  },
  {
    value: 'en',
    label: 'English',
    icon: '/assets/icons/ic_flag_en.svg',
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const [open, setOpen] = useState(null);
  const [language, setLanguage] = useState(getSelectedLanguage());
  const [lang, setLang] = useState(() => {
    const storedLanguage = getSelectedLanguage();
    return LANGS.find((o) => o.value === storedLanguage) || LANGS[0];
  });

  // Update lang when the language changes in localStorage
  useEffect(() => {
    const storedLanguage = getSelectedLanguage();
    const selectedLang = LANGS.find((o) => o.value === storedLanguage) || LANGS[0];
    setLang(selectedLang);
  }, [language]);

  // Rest of your code remains the same...

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (option) => {
    setSelectedLanguage(option.value);
    setLanguage(option.value);
    setOpen(null);
    window.location.reload();
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          padding: 1,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <img src={lang?.icon} alt={lang?.label} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem key={option.value} selected={option.value === language} onClick={() => handleClose(option)}>
              <Box
                component="img"
                alt={option.label}
                src={option.icon}
                sx={{
                  width: 28,
                  height: 28,
                  borderRadius: option.value === language ? '25%' : '0%',
                  marginRight: 2,
                }}
              />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </>
  );
}
