const FORMATS = ['auto', 'jpg', 'png', 'gif', 'webp', 'jxr', 'jp2', 'bmp'];

export function formatImage(url, options) {
	if (!url || !url.includes('res.cloudinary.com') || !options)
		return url;

	const params = [];
	const fillOptions = ['c_fill'];

	if (Number.isInteger(options.w)) fillOptions.push(`w_${options.w}`);
	if (Number.isInteger(options.h)) fillOptions.push(`h_${options.h}`);

	params.push(fillOptions.join(','));

	if (FORMATS.includes(options.format)) params.push(`f_${options.format}`);

	return url.replace('upload/', `upload/${params.join('/')}/`);
}