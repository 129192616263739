import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState } from 'react';
// @mui
import {
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
// components
import { useTranslation } from 'react-i18next';
import { Country } from 'country-state-city';
import i18n from '../i18n';
import Iconify from '../components/iconify';

import Scrollbar from '../components/scrollbar';
// sections
// mock
import { formatImage } from '../utils/formatImage';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { useFetchCoaches, deleteCoach } from '../services/coaches_service';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: i18n.t('Name'), alignRight: false },
  { id: 'city', label: i18n.t('City'), alignRight: false },
  { id: 'country', label: i18n.t('Country'), alignRight: false },
  { id: 'profession', label: i18n.t('Profession'), alignRight: false },
  { id: 'last_login', label: i18n.t('Last login'), alignRight: false },
  {},
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CoachesPage() {
  const { coaches, reloadCoaches } = useFetchCoaches();
  const [editingCoach, setEditingCoach] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedCoachId, setSelectedCoachId] = useState(null);
  const [selectedCoachIdForDelete, setSelectedCoachIdForDelete] = useState(null);

  const { t } = useTranslation();

  const handleOpenMenu = (event, id) => {
    setSelectedCoachIdForDelete(id);
    setEditingCoach({ anchor: event.currentTarget, id });
  };

  const handleOpenDeleteDialog = (id) => {
    setSelectedCoachIdForDelete(id);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedCoachIdForDelete) {
      try {
        // Call the deleteCoach function passing the selectedCoachIdForDelete
        await deleteCoach(selectedCoachIdForDelete);
        reloadCoaches();
      } catch (error) {
        console.error('Error deleting coach:', error);
      }

      handleCloseDeleteDialog();
      handleCloseMenu();
    }
  };
  const handleCloseMenu = () => {
    setSelectedCoachId(null);
    setEditingCoach(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - coaches.length) : 0;

  const filteredCoaches = applySortFilter(coaches, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredCoaches.length && !!filterName;

  function navigateToCoachDetailsPage(id) {
    navigate(`/dashboard/coaches/${id}`);
  }

  return (
    <>
      <Helmet>
        <title> {t('coaches')}</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('Coaches')}
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} href={'/dashboard/coaches/create'}>
            {t('New Coach')}
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={0} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredCoaches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name, city, country, image, profession, last_login: lastLogin } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1} style={{ cursor: 'pointer' }}>
                        <TableCell component="th" scope="row" onClick={() => navigateToCoachDetailsPage(id)}>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={formatImage(image, { w: 72, h: 72 })} />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left" onClick={() => navigateToCoachDetailsPage(id)}>
                          {city}
                        </TableCell>

                        <TableCell align="left" onClick={() => navigateToCoachDetailsPage(id)}>
                          {t(Country.getCountryByCode(country)?.name)}
                        </TableCell>
                        <TableCell align="left" onClick={() => navigateToCoachDetailsPage(id)}>
                          {profession}
                        </TableCell>
                        <TableCell align="left" onClick={() => navigateToCoachDetailsPage(id)}>
                          {
                            lastLogin ? <>
                              <Typography variant="body2">{lastLogin.timestamp.toDate().toLocaleString()}</Typography>
                              <Typography variant="caption">{lastLogin.location}</Typography>
                            </> : '-'
                          }
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            {t('Not found')}
                          </Typography>

                          <Typography variant="body2">
                            {t('No results found for')} &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> {t('Try checking for typos or using complete words')}
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={coaches.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('Row per page:')}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(editingCoach)}
        anchorEl={editingCoach?.anchor}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 175,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Button href={`/dashboard/coaches/${editingCoach?.id}/edit`}>
          <MenuItem sx={{ width: 150, display: 'flex', alignItems: 'center' }}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            {t('Edit')}
          </MenuItem>
        </Button>

        <Button onClick={() => handleOpenDeleteDialog(editingCoach?.id)}>
          <MenuItem sx={{ width: 140, display: 'flex', alignItems: 'center', color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            {t('Delete')}
          </MenuItem>
        </Button>
      </Popover>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{t('Confirm Delete')}</DialogTitle>
        <DialogContent>{t('Are you sure you want to delete this coach?')}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>{t('Cancel')}</Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
